<template>
  <v-card tile>
    <v-card-text class="text-center">
      <v-container class="pa-md-12">
        <v-row dense class="d-flex flex-row justify-center align-center">
          <v-col cols="12">
            <h2>
              {{ currentLangEs ? "Detalles" : "Details" }}
            </h2>
          </v-col>
          <v-col cols="12">
            <p class="">
              {{
                currentLangEs
                  ? "Detalles de recarga manual esperando validación"
                  : "Details from manual top up pending validation"
              }}
            </p>
          </v-col>
        </v-row>
        <v-row dense class="d-flex flex-column">
          <v-row dense>
            <v-col cols="12" md="6">
              <v-text-field
                outlined
                dense
                readonly
                :value="item.unique_id"
                label="Id"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                outlined
                dense
                readonly
                :value="item.reference"
                :label="currentLangEs ? 'Referencia' : 'Reference'"
              ></v-text-field
            ></v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" md="6">
              <v-text-field
                outlined
                dense
                readonly
                :value="item.payment_at | moment('DD-MM-YYYY')"
                :label="currentLangEs ? 'Fecha de recarga' : 'Top up date'"
              >
              </v-text-field
            ></v-col>
            <v-col cols="12" md="6">
              <v-text-field
                outlined
                dense
                readonly
                :value="item.created_at | moment('DD-MM-YYYY h:mm a')"
                :label="currentLangEs ? 'Fecha enviado' : 'Sent date'"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" md="6">
              <v-text-field
                outlined
                dense
                readonly
                :value="item.currency_id === '2' ? 'Bs' : 'USD'"
                :label="currentLangEs ? 'Moneda' : 'Currency'"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                outlined
                dense
                readonly
                :label="currentLangEs ? 'Monto' : 'Amount'"
                :value="value"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" md="6">
              <v-text-field
                outlined
                dense
                readonly
                :value="item.email_sender"
                :label="
                  currentLangEs
                    ? 'Correo electrónico para enviar factura'
                    : 'Email to send invoice'
                "
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                outlined
                dense
                readonly
                :value="
                  item.invoice_personalized
                    ? currentLangEs
                      ? 'Sí'
                      : 'Yes'
                    : 'No'
                "
                :label="
                  currentLangEs ? 'Factura personalizada' : 'Custom invoice'
                "
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-dialog
              v-model="dialog"
              fullscreen
              hide-overlay
              transition="dialog-bottom-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-img
                  :src="item.payment_receipt"
                  :max-width="$vuetify.breakpoint.mdAndUp ? 300 : 300"
                  :max-height="$vuetify.breakpoint.mdAndUp ? 300 : 300"
                  v-bind="attrs"
                  v-on="on"
                  class="image-dialog"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="primary"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </template>
              <v-card>
                <v-toolbar color="primary" tile dense>
                  <v-toolbar-title class="color-white-text">{{
                    currentLangEs ? "Comprobante de pago" : "Payment receipt"
                  }}</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-toolbar-items class="pt-1">
                    <v-btn icon dark @click="dialog = false">
                      <v-icon>{{ icons.mdiClose }}</v-icon>
                    </v-btn>
                  </v-toolbar-items>
                </v-toolbar>
                <v-row dense>
                  <v-col
                    cols="12"
                    class="d-flex align-center justify-center"
                    style="min-height: 90vh"
                  >
                    <v-img
                      :src="item.payment_receipt"
                      class="modal-image"
                      contain
                    >
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular
                            indeterminate
                            color="primary"
                          ></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-col>
                </v-row>
              </v-card>
            </v-dialog>
          </v-row>
        </v-row>
        <v-row
          class="d-flex flex-md-row flex-column-reverse justify-center align-center mt-10"
        >
          <v-col cols="12" md="4">
            <a
              text
              block
              color="primary"
              class="text-decoration-underline text-lg"
              :class="!$vuetify.theme.isDark && 'color-blue-text'"
              @click="handleClose()"
            >
              {{ currentLangEs ? "Regresar" : "Go back" }}
            </a>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>
<script>
import { mdiClose } from "@mdi/js";

export default {
  props: {
    item: {
      type: Object,
    },
  },
  data() {
    return {
      value: 0,
      icons: {
        mdiClose,
      },
      dialog: false,
    };
  },
  computed: {
    currentLangEs() {
      return this.$vuetify.lang.current === "es";
    },
  },
  methods: {
    handleClose(success) {
      if (success) {
        this.$emit("submit", true);
      }
      this.$emit("submit", null);
    },
  },
  created() {
    if (this.item.currency_id === "2") {
      let value = this.$options.filters.currency(this.item.amount, {
        symbol: "Bs.",
        thousandsSeparator: ",",
        fractionCount: 2,
        fractionSeparator: ".",
        symbolPosition: "front",
        symbolSpacing: false,
        avoidEmptyDecimals: undefined,
      });
      this.value = value;
    } else {
      let value = this.$options.filters.currency(this.item.amount);
      this.value = value;
    }
  },
};
</script>
<style lang="sass" scoped>
.vuedl-layout__closeBtn
  color: rgba(58, 53, 65, 0.54)
    // color: white
    // opacity: 1
    // margin-top: 8px

.v-dialog.vuedl-layout.v-dialog--active.v-dialog--fullscreen
  .v-card.v-sheet.theme--light.rounded-0
    min-height: 100vh

a
  text-decoration: none

.modal-image
  max-width: 80vw

.image-dialog:hover
  cursor: pointer
</style>
