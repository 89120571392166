var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_c('v-data-table',{staticClass:"dataTable elevation-1",attrs:{"loading":_vm.loading,"headers":_vm.computedHeaders,"disable-sort":true,"items":_vm.items,"calculate-widths":"","hide-default-footer":"","disable-pagination":"","fixed-header":"","height":_vm.computedHeight,"dense":true,"mobile-breakpoint":"0"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"d-flex flex-row align-center justify-end",attrs:{"cols":"12"}},[_c('v-tooltip',{attrs:{"top":"","max-width":300},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.handleRefresh()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiRefresh)+" ")])],1)]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("Refresh table"))+" ")])])],1)],1)]},proxy:true},{key:"item.payment_at",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("moment")(value,"DD-MM-YYYY"))+" ")]}},{key:"item.created_at",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("moment")(value,"DD-MM-YYYY h:mm a"))+" ")]}},{key:"item.currency_id",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value === "2" ? "Bs" : "USD")+" ")]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"color-green-text"},[(item.currency_id === '1')?_c('span',[_vm._v(" "+_vm._s(_vm._f("currency")(item.amount))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm._f("currency")(item.amount,{ symbol: "Bs.", thousandsSeparator: ",", fractionCount: 2, fractionSeparator: ".", symbolPosition: "front", symbolSpacing: false, avoidEmptyDecimals: undefined, }))+" ")])])]}},{key:"item.invoice_personalized",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value ? _vm.$t("yes") : _vm.$t("no"))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"rounded":".rounded-lg","offset-y":"","disabled":_vm.loading},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiDotsVertical)+" ")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.toggleDetailsModal([item])}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("manual_payments_pending_see_details")))])],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }