<template>
  <v-card tile class="d-flex flex-column justify-center align-center">
    <!-- <v-card-title class="text-h5 primary mb-2">
      <span class="white--text">
        Notificación
      </span>
    </v-card-title> -->

    <v-card-text class="text-center">
      <v-container class="pa-md-12 pa-0">
        <v-row dense class="d-flex flex-row justify-center align-center">
          <v-col cols="12">
            <h2>Pago móvil automatizado</h2>
            <v-row
              dense
              class="d-flex flex-row justify-center align-center mt-1"
            >
              <v-col
                cols="3"
                class="ma-0 pa-0 d-flex flex-row justify-center align-center"
              >
                <div
                  class="d-flex flex-column justify-center align-center payment-method"
                >
                  <div
                    class="payment-method-avatar d-flex flex-row justify-center align-center background-green elevation-6 backgroung-green"
                  >
                    <v-img
                      src="../assets/payment-methods/pago-movil.svg"
                      :max-width="$vuetify.breakpoint.mdAndUp ? 40 : 30"
                      :max-height="$vuetify.breakpoint.mdAndUp ? 40 : 30"
                      contain
                    >
                    </v-img>
                  </div>
                  <div
                    class="d-flex flex-row justify-center payment-method-name font-weight-bold mt-1"
                    style="width: 100%"
                  >
                    Pago móvil
                  </div>
                  <div
                    class="d-flex flex-column flex-md-row justify-center payment-method-currency align-center"
                    style="width: 100px"
                  >
                    <span class="font-weight-bold"> Bs </span>
                  </div>
                </div>
              </v-col>
            </v-row>
            <p>
              Realiza tus recargas de balance corporativo con nuestro sistema de
              pago móvil automatizado
            </p>
          </v-col>
        </v-row>
        <template>
          <v-stepper v-model="activeStep" class="custom-header" elevation="0">
            <!-- Header -->
            <v-stepper-header>
              <!-- Header: Step 1 -->
              <v-stepper-step
                :complete="activeStep > 1"
                step="1"
                :color="activeStep > 1 ? '#3BD4AE' : '#EF4370'"
              >
                <div class="d-flex align-center">
                  <span class="text--primary text-4xl font-weight-bold me-3"
                    >01</span
                  >
                  <div class="d-flex flex-column" v-if="activeStep === 1">
                    <span class="text--primary text-sm font-weight-semibold"
                      >Cantidad a recargar</span
                    >
                    <span class="text--secondary text-xs"
                      >Defina la cantidad a recargar</span
                    >
                  </div>
                </div>
              </v-stepper-step>

              <v-divider></v-divider>

              <!-- Header: Step 2 -->
              <v-stepper-step
                :complete="activeStep > 2"
                step="2"
                :color="activeStep > 2 ? '#3BD4AE' : '#EF4370'"
              >
                <div class="d-flex align-center">
                  <span class="text--primary text-4xl font-weight-bold me-3"
                    >02</span
                  >
                  <div class="d-flex flex-column" v-if="activeStep === 2">
                    <span class="text--primary text-sm font-weight-semibold"
                      >Pago móvil a realizar</span
                    >
                    <span class="text--secondary text-xs"
                      >Monto y coordenadas<br />
                      para realizar pago móvil</span
                    >
                  </div>
                </div>
              </v-stepper-step>

              <v-divider></v-divider>

              <!-- Header: Step 3 -->
              <v-stepper-step
                step="3"
                :color="
                  activeStep === 3 && pagoMovilSuccess ? '#3BD4AE' : '#EF4370'
                "
                :complete="activeStep === 3 && pagoMovilSuccess"
              >
                <div class="d-flex align-center">
                  <span class="text--primary text-4xl font-weight-bold me-3"
                    >03</span
                  >
                  <div class="d-flex flex-column" v-if="activeStep === 3">
                    <span class="text--primary text-sm font-weight-semibold"
                      >Validación de pago móvil</span
                    >
                    <span
                      class="text--secondary text-xs"
                      v-if="!pagoMovilSuccess"
                      >Espera mientras validamos<br />
                      tu pago móvil</span
                    >
                    <span
                      class="text--secondary text-xs"
                      v-if="pagoMovilSuccess"
                      >Pago móvil validado</span
                    >
                  </div>
                </div>
              </v-stepper-step>
            </v-stepper-header>

            <!-- Stepper Items -->
            <v-stepper-items>
              <!-- Stepper Content: Step 1 -->
              <v-stepper-content step="1">
                <v-card class="pt-8" elevation="0">
                  <v-row dense justify="center">
                    <v-col cols="12" md="3">
                      <v-text-field
                        label="Cantidad a agregar"
                        outlined
                        dense
                        v-model.lazy="price"
                        v-money="money"
                        prefix="$"
                        @keydown="nameKeydown($event)"
                        max-width="200"
                      />
                    </v-col>
                  </v-row>
                </v-card>

                <div class="d-flex justify-center">
                  <v-btn
                    color="primary"
                    @click="activeStep = 2"
                    :disabled="price <= 0"
                  >
                    Siguiente
                  </v-btn>
                </div>
              </v-stepper-content>

              <!-- Stepper Content: Step 2 -->
              <v-stepper-content step="2">
                <ValidationObserver v-slot="{ invalid }">
                  <v-card class="pt-8 pb-8" elevation="0" v-show="!loading">
                    <!-- Datos bancarios -->
                    <v-row dense>
                      <v-col
                        cols="12"
                        md="4"
                        style="border-right: 1px solid #ef4370"
                      >
                        <v-row dense>
                          <v-col cols="12" class="d-flex justify-center">
                            <h3>Coordenadas de pago móvil para transferir</h3>
                          </v-col>
                          <v-col cols="12">
                            Monto:
                            <span class="font-weight-bold">
                              {{
                                computedBsTransfer
                                  | currency({
                                    symbol: "Bs.",
                                    thousandsSeparator: ",",
                                    fractionCount: 2,
                                    fractionSeparator: ".",
                                    symbolPosition: "front",
                                    symbolSpacing: false,
                                    avoidEmptyDecimals: undefined,
                                  })
                              }}
                            </span>
                            <span class="ml-2">
                              <v-tooltip top v-model="showTooltipAmount">
                                <template v-slot:activator="{ attrs }">
                                  <v-btn
                                    v-bind="attrs"
                                    icon
                                    @click="handleCopyClipboard('amountNumber')"
                                  >
                                    <v-icon>{{ icons.mdiContentCopy }}</v-icon>
                                  </v-btn>
                                </template>
                                <span>¡Monto copiado!</span>
                              </v-tooltip>
                            </span>
                          </v-col>
                          <v-col cols="12">
                            Número de Teléfono:
                            <span class="font-weight-bold">
                              {{ bank.number }}
                            </span>
                            <span class="ml-2">
                              <v-tooltip top v-model="showTooltipNumber">
                                <template v-slot:activator="{ attrs }">
                                  <v-btn
                                    v-bind="attrs"
                                    icon
                                    @click="handleCopyClipboard('bankNumber')"
                                  >
                                    <v-icon>{{ icons.mdiContentCopy }}</v-icon>
                                  </v-btn>
                                </template>
                                <span>¡Número de Teléfono copiado!</span>
                              </v-tooltip>
                            </span>
                          </v-col>
                          <v-col cols="12">
                            RIF:
                            <span class="font-weight-bold">
                              {{ bank.rif }}
                            </span>
                            <span class="ml-2">
                              <v-tooltip top v-model="showTooltipRif">
                                <template v-slot:activator="{ attrs }">
                                  <v-btn
                                    v-bind="attrs"
                                    icon
                                    @click="handleCopyClipboard('rifNumber')"
                                  >
                                    <v-icon>{{ icons.mdiContentCopy }}</v-icon>
                                  </v-btn>
                                </template>
                                <span>¡RIF copiado!</span>
                              </v-tooltip>
                            </span>
                          </v-col>
                          <v-col cols="12">
                            Banco:
                            <span class="font-weight-bold">
                              {{ bank.name }}
                            </span>
                          </v-col>
                          <v-col
                            v-if="bank.imageURL"
                            cols="12"
                            class="d-flex flex-row justify-center align-center"
                          >
                            <v-img
                              contain
                              max-width="300"
                              :src="bank.imageURL"
                              alt="imageURL"
                            >
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height ma-0"
                                  align="center"
                                  justify="center"
                                >
                                  <v-progress-circular
                                    indeterminate
                                    color="primary"
                                  ></v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12" md="8" class="pl-4">
                        <v-row dense>
                          <v-col cols="12">
                            <h3>
                              Indica los datos relacionados con el pago móvil
                            </h3>
                          </v-col>
                        </v-row>
                        <v-row dense class="">
                          <v-col cols="12" md="8">
                            <ValidationProvider
                              name="Banco"
                              rules="required"
                              v-slot="{ errors }"
                              slim
                            >
                              <v-autocomplete
                                v-model="pagoMovilForm.bank"
                                :items="bankList"
                                item-value="code"
                                :menu-props="{ contentClass: 'list-style' }"
                                dense
                                outlined
                                label="Banco"
                                :error-messages="errors[0] && errors[0]"
                                :filter="handleFilterAutocompleteBanks"
                              >
                                <template v-slot:selection="{ item }">
                                  <span
                                    :style="
                                      (item.code + item.name).length > 35 &&
                                      'font-size: 0.6rem;'
                                    "
                                  >
                                    {{ item.code }} - {{ item.name }}
                                  </span>
                                </template>
                                <template v-slot:item="{ item }">
                                  {{ item.code }} - {{ item.name }}
                                </template>
                                <template v-slot:append-outer>
                                  <div class="mr-4">
                                    <v-tooltip top :max-width="200">
                                      <template #activator="{ on, attrs }">
                                        <v-icon v-bind="attrs" v-on="on">
                                          {{ icons.mdiInformationOutline }}
                                        </v-icon>
                                      </template>
                                      <span>
                                        Este campo es autocompletado
                                        <br />
                                        Puedes buscar bancos por:
                                        <br />
                                        <ul class="mt-2">
                                          <li>Nombre</li>
                                          <li>Código</li>
                                        </ul>
                                      </span>
                                    </v-tooltip>
                                  </div>
                                </template>
                              </v-autocomplete>
                            </ValidationProvider>
                          </v-col>
                          <v-col cols="12" md="2">
                            <ValidationProvider
                              name="Operadora"
                              rules="required"
                              v-slot="{ errors }"
                              slim
                            >
                              <v-select
                                :items="pagoMovilForm.phonePrefixList"
                                label="Operadora"
                                outlined
                                dense
                                v-model="pagoMovilForm.phonePrefix"
                                :error-messages="errors[0] && errors[0]"
                              >
                              </v-select>
                            </ValidationProvider>
                          </v-col>
                          <v-col cols="12" md="2">
                            <ValidationProvider
                              name="Teléfono"
                              rules="required|min:7|max:7|numeric"
                              v-slot="{ errors }"
                              slim
                            >
                              <PhoneInput
                                label="Teléfono"
                                v-model="pagoMovilForm.phone"
                                dense
                                outlined
                                :long="false"
                                :error-messages="errors[0] && errors[0]"
                              />
                            </ValidationProvider>
                          </v-col>
                        </v-row>
                        <v-row dense class="">
                          <v-col cols="12" md="6">
                            <ValidationProvider
                              name="Fecha de pago"
                              rules="required"
                              v-slot="{ errors }"
                            >
                              <DatePickerInput
                                label="Fecha de pago"
                                placeholder="Ingrese su Fecha de pago"
                                v-model="pagoMovilForm.date"
                                outlined
                                dense
                                :max="maxDay"
                                :error-messages="errors[0] && errors[0]"
                              />
                            </ValidationProvider>
                          </v-col>
                          <v-col cols="12" md="6">
                            <ValidationProvider
                              name="Últimos 4 dígitos de referencia"
                              rules="required|min:4|max:4|numeric"
                              v-slot="{ errors }"
                            >
                              <v-text-field
                                label="Últimos 4 dígitos de referencia"
                                v-model="pagoMovilForm.digits"
                                outlined
                                dense
                                v-mask="'####'"
                                :error-messages="errors[0] && errors[0]"
                              >
                                <template v-slot:append-outer>
                                  <v-tooltip bottom max-width="150">
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-icon
                                        color="primary"
                                        v-bind="attrs"
                                        v-on="on"
                                      >
                                        {{ icons.mdiInformationOutline }}
                                      </v-icon>
                                    </template>
                                    <span
                                      >Indicar los últimos 4 dígitos de la
                                      referencia otorgada por tu banco del pago
                                      móvil</span
                                    >
                                  </v-tooltip>
                                </template>
                              </v-text-field>
                            </ValidationProvider>
                          </v-col>
                        </v-row>
                        <v-row dense class="ma-0 pa-0">
                          <v-col
                            md="6"
                            cols="12"
                            class="d-flex align-center ma-0 pa-0"
                          >
                            <ValidationProvider
                              :name="'¿Desea factura personalizada?'"
                              rules="required"
                              v-slot="{ errors }"
                              slim
                            >
                              <!-- v-model="manualTopUpForm.details.customInvoice" -->
                              <v-radio-group
                                v-model="pagoMovilForm.customInvoice"
                                :error-messages="errors[0] && errors[0]"
                                :label="'¿Desea factura personalizada?'"
                                dense
                                row
                              >
                                <v-radio :label="'Sí'" :value="true"></v-radio>
                                <v-radio label="No" :value="false"></v-radio>
                              </v-radio-group>
                            </ValidationProvider>
                          </v-col>
                        </v-row>
                        <v-row dense class="ma-0 pa-0 d-flex align-baseline">
                          <v-col
                            cols="12"
                            md="6"
                            class="d-flex align-center ma-0 pa-0"
                          >
                            <ValidationProvider
                              :name="'¿Esta recarga corresponde a una proforma?'"
                              rules="required"
                              v-slot="{ errors }"
                              slim
                            >
                              <!-- v-model="manualTopUpForm.details.customInvoice" -->
                              <v-radio-group
                                :error-messages="errors[0] && errors[0]"
                                :label="'¿Esta recarga corresponde a una proforma?'"
                                dense
                                row
                                v-model="pagoMovilForm.proforma"
                              >
                                <v-radio :label="'Sí'" :value="true"></v-radio>
                                <v-radio label="No" :value="false"></v-radio>
                              </v-radio-group>
                            </ValidationProvider>
                          </v-col>
                          <v-col
                            cols="12"
                            md="6"
                            class="d-flex align-center ma-0 pa-0"
                            v-if="pagoMovilForm.proforma"
                          >
                            <ValidationProvider
                              v-if="pagoMovilForm.proforma"
                              :name="'Proforma'"
                              rules="required"
                              v-slot="{ errors }"
                              slim
                            >
                              <v-text-field
                                v-model="pagoMovilForm.proformaNumber"
                                label="Proforma"
                                outlined
                                dense
                                :error-messages="errors[0] && errors[0]"
                              >
                              </v-text-field>
                            </ValidationProvider>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>

                    <!-- <br />
                    <v-divider></v-divider>
                    <br /> -->
                    <!-- datos -->
                  </v-card>
                  <v-card v-show="loading" class="pt-8 pb-8" elevation="0">
                    <v-progress-circular
                      class="mt-10 mb-10"
                      indeterminate
                      :size="64"
                      color="primary"
                    />
                    <p class="text-xl">Obteniendo datos...</p>
                  </v-card>

                  <div
                    class="d-flex align-center justify-center"
                    v-if="!loading"
                  >
                    <v-btn
                      outlined
                      color="primary"
                      @click="activeStep = 1"
                      :disabled="loading"
                    >
                      Volver
                    </v-btn>
                    <v-btn
                      class="ml-2"
                      color="primary"
                      @click="activeStep = 3"
                      :disabled="loading || invalid"
                    >
                      Siguiente
                    </v-btn>
                  </div>
                </ValidationObserver>
              </v-stepper-content>

              <!-- Stepper Content: Step 3 -->
              <v-stepper-content step="3">
                <v-card class="pt-8 pb-8" elevation="0" v-show="!loading">
                  <template v-if="!pagoMovilSuccess">
                    <v-row dense>
                      <v-col cols="12" class="d-flex flex-column align-center">
                        <v-img
                          src="@/views/Balance/assets/notFound.png"
                          width="250"
                          class="wobble-hor-bottom"
                        >
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height ma-0"
                              align="center"
                              justify="center"
                            >
                              <v-progress-circular
                                indeterminate
                                color="primary"
                              ></v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                        <br />
                        <h2>¡Lo sentimos!</h2>
                        <template v-if="errorCode === '705'">
                          <h3 class="fontUrbanist">
                            Pago móvil
                            <span class="font-weight-bold">no encontrado</span>
                            en la base de datos de Ridery
                          </h3>
                        </template>
                        <template v-if="errorCode === '704'">
                          <h3 class="fontUrbanist">
                            El pago móvil ya fue
                            <span class="font-weight-bold">utilizado</span>
                          </h3>
                        </template>
                        <span>
                          Puedes
                          <span class="font-weight-bold">revisar</span> los
                          datos del pago móvil y
                          <span class="font-weight-bold">reintentar</span> o
                          solicitar asistencia
                        </span>
                      </v-col>
                    </v-row>
                  </template>
                  <template v-if="pagoMovilSuccess">
                    <v-row dense>
                      <v-col cols="12" class="d-flex flex-column align-center">
                        <v-img
                          src="@/views/Balance/assets/found.png"
                          width="250"
                          class="pulsate-fwd"
                        >
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height ma-0"
                              align="center"
                              justify="center"
                            >
                              <v-progress-circular
                                indeterminate
                                color="primary"
                              ></v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                        <br />
                        <h2>¡Éxito!</h2>
                        <h3 class="fontUrbanist">
                          Pago móvil
                          <span class="font-weight-bold">encontrado</span> en la
                          base de datos de Ridery
                        </h3>
                        <span>
                          Se <span class="font-weight-bold">agregaron </span>
                          <span class="font-weight-bold color-green-text">{{
                            pagoMovilDetails.wallet_amount | currency
                          }}</span>
                          a tu
                          <span class="font-weight-bold"
                            >balance corporativo</span
                          >
                          de un pago móvil de
                          <span class="font-weight-bold">{{
                            pagoMovilDetails.wallet_amount_bs
                              | currency({
                                symbol: "Bs.",
                                thousandsSeparator: ",",
                                fractionCount: 2,
                                fractionSeparator: ".",
                                symbolPosition: "front",
                                symbolSpacing: false,
                                avoidEmptyDecimals: undefined,
                              })
                          }}</span>
                          <br />
                          Balance corporativo:
                          <span class="font-weight-bold">{{
                            pagoMovilDetails.total_wallet_amount | currency
                          }}</span>
                        </span>
                      </v-col>
                    </v-row>
                  </template>
                </v-card>
                <v-card v-show="loading" class="pt-8 pb-8" elevation="0">
                  <v-progress-circular
                    class="mt-10 mb-10"
                    indeterminate
                    :size="64"
                    color="primary"
                  />
                  <p class="text-xl">Validando datos de pago móvil...</p>
                </v-card>

                <div class="d-flex justify-center" v-if="!loading">
                  <v-btn
                    v-if="!pagoMovilSuccess"
                    outlined
                    @click="activeStep = 2"
                    color="primary"
                    :small="!$vuetify.breakpoint.mdAndUp"
                  >
                    Revisar datos de pago móvil
                  </v-btn>
                  <v-btn
                    v-if="pagoMovilSuccess"
                    color="primary"
                    class="ml-2"
                    @click="handleClose(true)"
                  >
                    Completar
                  </v-btn>
                </div>
                <div
                  class="d-flex justify-center mt-4"
                  v-if="activeStep === 3 && !pagoMovilSuccess && !loading"
                >
                  <a
                    class="text-decoration-underline text-lg"
                    href="https://api.whatsapp.com/send?phone=584128835418&text=Soy%20un%20cliente%20corporativo.%F0%9F%91%A9%F0%9F%8F%BB%E2%80%8D%F0%9F%92%BC%F0%9F%91%A8%F0%9F%8F%BB%E2%80%8D%F0%9F%92%BC"
                    target="_blank"
                  >
                    Solicitar asistencia
                  </a>
                </div>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </template>
        <v-row
          class="d-flex flex-md-row flex-column-reverse justify-center align-center"
          v-if="activeStep !== 3"
        >
          <v-col cols="12" md="4" v-if="!loading">
            <a
              text
              block
              color="primary"
              class="text-decoration-underline text-lg"
              :class="!$vuetify.theme.isDark && 'color-blue-text'"
              @click="handleClose()"
            >
              Cancelar
            </a>
          </v-col>
          <!-- <v-col cols="12" md="4">
            <v-btn color="primary" block @click="handleClose()">
              Aceptar
            </v-btn>
          </v-col> -->
        </v-row>
      </v-container>
      <v-container></v-container>
    </v-card-text>
  </v-card>
</template>
<script>
import { mdiWhatsapp } from "@mdi/js";
import {
  mdiMagnify,
  mdiAlert,
  mdiContentCopy,
  mdiInformationOutline,
} from "@mdi/js";
import { mapActions, mapState } from "vuex";
import axios from "@/plugins/axios";
import { VMoney } from "v-money";
import bankList from "@/api/bankList";
import PhoneInput from "@/components/PhoneInput";
import DatePickerInput from "@/components/DatePickerInput";
import { mask } from "vue-the-mask";

export default {
  props: {},
  components: {
    PhoneInput,
    DatePickerInput,
  },
  directives: { mask },
  data() {
    return {
      icons: {
        mdiWhatsapp,
        mdiMagnify,
        mdiAlert,
        mdiContentCopy,
        mdiInformationOutline,
      },
      loading: false,
      price: 0,
      money: {
        decimal: ".",
        thousands: ",",
        precision: 2,
      },
      activeStep: 1,
      bank: {
        number: "04126509977",
        rif: "J-500969210",
        name: "Bancamiga",
        imageURL: undefined,
      },
      showTooltipAmount: false,
      showTooltipRif: false,
      showTooltipNumber: false,
      currencyRate: 1,
      bankList: bankList,
      pagoMovilForm: {
        bank: "",
        phonePrefixList: ["0412", "0414", "0424", "0416", "0426"],
        phonePrefix: "",
        phone: "",
        date: "",
        digits: "",
        customInvoice: undefined,
        proforma: undefined,
        proformaNumber: "",
      },
      pagoMovilSuccess: undefined,
      errorCode: null,
      pagoMovilDetails: {
        wallet_amount: "",
        wallet_amount_bs: "",
        total_wallet_amount: "",
      },
    };
  },
  beforeDestroy() {
    clearTimeout(this._timerId);
  },
  methods: {
    ...mapActions(["setLoading"]),

    handleClose(success) {
      if (success) {
        this.$emit("submit", true);
      }
      this.$emit("submit", null);
    },

    nameKeydown(e) {
      if (/^\W$/.test(e.key)) {
        e.preventDefault();
      }
    },
    handleCopyClipboard(reference) {
      switch (reference) {
        case "bankNumber":
          navigator.clipboard.writeText(this.bank.number);
          this.showTooltipNumber = true;
          this._timerId = setTimeout(
            () => (this.showTooltipNumber = false),
            800
          );
          break;
        case "rifNumber":
          navigator.clipboard.writeText(this.bank.rif);
          this.showTooltipRif = true;
          this._timerId = setTimeout(() => (this.showTooltipRif = false), 800);
          break;
        case "amountNumber":
          navigator.clipboard.writeText(this.computedBsTransfer);
          this.showTooltipAmount = true;
          this._timerId = setTimeout(
            () => (this.showTooltipAmount = false),
            800
          );
          break;
        default:
          break;
      }
    },
    handleFilterAutocompleteBanks(_item, _queryText, _itemText) {
      const searchText = _queryText.toLowerCase();

      const name = _item.name.toLowerCase();
      const code = _item.code.toLowerCase();

      return name.indexOf(searchText) > -1 || code.indexOf(searchText) > -1;
    },
    async getPagoMovilDetails() {
      this.setLoading(true);
      try {
        const getPagoMovilDetailsResponse = await axios.get("/me");
        if (!getPagoMovilDetailsResponse?.data?.pago_movil_data) {
          throw new Error("No pago móvil definition");
        }

        // Objeto vacio
        if (
          Object.keys(getPagoMovilDetailsResponse.data.pago_movil_data)
            .length === 0
        ) {
          console.info("No pago móvil definition");
          return;
        }

        const {
          pago_movil_data: {
            bank_name,
            bank_code,
            rif,
            phone_number,
            bank_logo,
          },
        } = getPagoMovilDetailsResponse.data;

        this.bank = {
          number: phone_number,
          rif: rif.replace(/^([A-Za-z])(\d+)/, "$1-$2"),
          name: `${bank_code} - ${bank_name}`,
          imageURL: bank_logo,
        };
      } catch (error) {
        console.error(error);
        this.$dialog.notify.error(error.message);
        this.$emit("submit", null);
      } finally {
        this.setLoading(false);
      }
    },
  },
  computed: {
    ...mapState(["stateDriverCancelledEvent"]),

    computedPrice() {
      if (this.price.length >= 8) {
        return parseFloat(this.price.replace(/,/g, ""));
      } else {
        return parseFloat(this.price);
      }
    },
    computedBsTransfer() {
      return parseFloat(this.computedPrice * this.currencyRate).toFixed(2);
    },
    maxDay() {
      let today = new Date();
      return this.$moment(today).add(-4, "hours").toISOString();
    },
  },
  watch: {
    activeStep: async function (newVal) {
      if (newVal === 2) {
        this.loading = true;
        try {
          const { data } = await axios.get("/get_currency_rate");
          if (data.success) {
            this.currencyRate = data.currency_rate;
          } else {
            throw new Error("Error getting Currency Rate");
          }
        } catch (error) {
          this.$dialog.notify.error(error.message);
        }
        this.loading = false;
      }
      if (newVal === 3) {
        this.loading = true;
        try {
          const {
            phone,
            phonePrefix,
            bank,
            date,
            digits,
            customInvoice,
            proforma,
            proformaNumber,
          } = this.pagoMovilForm;
          let params = {
            phone: `58${phonePrefix.slice(1)}${phone}`,
            originBank: bank,
            date: date,
            // amount: this.computedBsTransfer,
            // amount: '12.53',
            reference: `${digits}`,
            custom_invoice: customInvoice,
            proforma: proforma && proformaNumber,
          };
          // corporate_add_wallet_with_pago_movil
          const { data } = await axios.post("/pm", params);
          if (data.success) {
            console.log(data);
            this.pagoMovilSuccess = true;
            const { wallet_amount, wallet_amount_bs, total_wallet_amount } =
              data;
            this.pagoMovilDetails["wallet_amount"] = wallet_amount;
            this.pagoMovilDetails["wallet_amount_bs"] = wallet_amount_bs;
            this.pagoMovilDetails["total_wallet_amount"] = total_wallet_amount;
          } else {
            console.log(data);

            if (data.error_code === "705" || data.error_code === "3008") {
              await new Promise((r) => setTimeout(r, 31000));
              let dataRetry = await axios.post("/pm", params);
              if (dataRetry.data.success) {
                this.pagoMovilSuccess = true;
                const { wallet_amount, wallet_amount_bs, total_wallet_amount } =
                  data;
                this.pagoMovilDetails["wallet_amount"] = wallet_amount;
                this.pagoMovilDetails["wallet_amount_bs"] = wallet_amount_bs;
                this.pagoMovilDetails["total_wallet_amount"] =
                  total_wallet_amount;
              } else {
                this.pagoMovilSuccess = false;
                this.errorCode = data.error_code;
              }
            }
            this.pagoMovilSuccess = false;
            this.errorCode = data.error_code;
          }
        } catch (error) {
          this.$dialog.notify.error(error.message);
        }
        this.loading = false;
      }
    },
  },
  async created() {
    await this.getPagoMovilDetails();
  },
};
</script>
<style lang="sass" scoped>
.vuedl-layout__closeBtn
  color: rgba(58, 53, 65, 0.54)
    // color: white
    // opacity: 1
    // margin-top: 8px

.v-dialog.vuedl-layout.v-dialog--active.v-dialog--fullscreen
  .v-card.v-sheet.theme--light.rounded-0
    min-height: 100vh

a
  text-decoration: none

// .custom-margin
  // margin-top: -10px !important

// .test.v-btn:not(.v-btn--round).v-size--x-large
//   height: 55.22px !important

.custom-width
  width: 46%

.custom-margin-top
  margin-top: -10px
</style>

<style lang="scss" scoped>
.payment-method {
  width: 100px;
  height: 100px;
  &-avatar {
    min-width: 60px;
    min-height: 60px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }
  &-currency {
    font-size: 10px;
  }
  &-name {
    font-size: 10px;
    text-align: center;
  }
}
</style>
