<template>
  <v-card tile class="px-4 pt-4 pb-8 dialog-container">
    <v-card-text>
      <div class="d-flex justify-center w-full">
        <div style="width: 70%">
          <v-img src="@/assets/landing/logo_header.svg" contain></v-img>
        </div>
      </div>

      <p
        class="pt-6 text-2xl fontUrbanist primary--text"
        style="text-align: center"
      >
        <strong class="success--text"> Estimado cliente</strong>, recuerda
        realizar la solicitud de tu factura antes de realizar tu recarga
      </p>

      <v-divider></v-divider>
      <div class="d-flex justify-center">
        <v-checkbox
          v-model="show"
          label="No volver a mostrar este mensaje"
        ></v-checkbox>
      </div>

      <v-divider></v-divider>
      <p class="text-lg text-center mt-4">Descarga nuestra app</p>
      <div class="d-flex justify-center align-center" style="gap: 20px">
        <span class="d-flex flex-row align-center">
          <a
            class="text-decoration-none d-flex align-center"
            href="https://play.google.com/store/apps/details?id=com.ridery&hl=es_419&gl=US"
            target="_blank"
          >
            <v-img src="@/views/Balance/assets/Google_play.png" width="150" />
          </a>
        </span>
        <span class="d-flex flex-row align-center">
          <a
            class="text-decoration-none d-flex align-center"
            href="https://apps.apple.com/ve/app/ridery/id1553877900"
            target="_blank"
          >
            <v-img src="@/views/Balance/assets/App_Store.png" width="150" />
          </a>
        </span>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  computed: {
    ...mapState("showProformModal", ["dontShowAgain"]),
    show: {
      get() {
        return this.$store.state.showProformModal.show;
      },
      set(value) {
        this.$store.commit("showProformModal/SET_DONT_SHOW_AGAIN", value);
      },
    },
  },
  methods: {
    ...mapActions("showProformModal", ["updateDontShowAgain"]),
  },
  watch: {
    show(val) {
      this.updateDontShowAgain(val);
    },
  },
};
</script>

<style lang="scss" scoped>
.v-dialog.vuedl-layout.v-dialog--active.v-dialog--fullscreen {
  .v-card.v-sheet.theme--light.rounded-0,
  .v-card.v-sheet.theme--dark.rounded-0 {
    min-height: 100vh !important;
  }
}

.dialog-container {
  min-height: 200px;
}
</style>
