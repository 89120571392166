<template>
  <v-row class="ma-0 pa-0 d-flex flex-column">
    <template v-if="!loadingCarrousel">
      <v-row dense class="ma-0 pa-0 d-flex flex-row justify-end align-center">
        <v-col
          cols="3"
          lg="1"
          class="pl-2 mt-4 d-flex flex-row align-center justify-center"
          v-for="paymentBs in computedTopUpOptionsBs"
          :key="paymentBs.index"
        >
          <div
            class="d-flex flex-column justify-start align-center payment-method"
            @click="handleMethodClick(paymentBs)"
          >
            <div
              class="
                payment-method-avatar
                d-flex
                flex-row
                justify-center
                align-center
                background-green
                elevation-6
              "
            >
              <v-img
                :src="handlePaymentMethodAvatar(paymentBs)"
                :max-width="$vuetify.breakpoint.mdAndUp ? 40 : 30"
                :max-height="$vuetify.breakpoint.mdAndUp ? 40 : 30"
                contain
              >
              </v-img>
            </div>
            <div
              class="
                d-flex
                flex-row
                justify-center
                payment-method-name
                font-weight-bold
                mt-1
              "
              style="width: 100%"
            >
              {{ paymentBs.name }}
            </div>
            <div
              class="
                d-flex
                flex-column flex-md-row
                justify-center
                payment-method-currency
                align-center
              "
              style="width: 100px"
            >
              <span class="font-weight-bold"> Bs </span>
              <span class="ml-1">
                {{ paymentBs.type === "automated" ? "Automatizada" : "Manual" }}
              </span>
            </div>
          </div>
        </v-col>
        <v-col
          cols="3"
          lg="1"
          class="pl-2 mt-4 d-flex flex-row align-center justify-center"
          v-for="paymentUsd in computedTopUpOptionsUsd"
          :key="paymentUsd.index"
        >
          <div
            class="d-flex flex-column justify-start align-center payment-method"
            @click="handleMethodClick(paymentUsd)"
          >
            <div
              class="
                payment-method-avatar
                d-flex
                flex-row
                justify-center
                align-center
                background-dark-blue
                elevation-6
              "
            >
              <v-img
                :src="handlePaymentMethodAvatar(paymentUsd)"
                max-width="40"
                max-height="40"
                contain
              >
              </v-img>
            </div>
            <div
              class="
                d-flex
                flex-row
                justify-center
                payment-method-name
                font-weight-bold
                mt-1
              "
              style="width: 100%"
            >
              {{ paymentUsd.name }}
            </div>
            <div
              class="
                d-flex
                flex-column flex-md-row
                justify-center
                payment-method-currency
                align-center
              "
              style="width: 100px"
            >
              <span class="font-weight-bold"> USD </span>
              <span class="ml-1">
                {{
                  paymentUsd.type === "automated" ? "Automatizada" : "Manual"
                }}
              </span>
            </div>
          </div>
        </v-col>
        <template
          v-if="
            computedTopUpOptionsUsd.length === 0 &&
            computedTopUpOptionsBs.length === 0
          "
        >
          <v-col cols="12" class="d-flex flex-row justify-center">
            No hay opciones de recarga disponibles
          </v-col>
        </template>
      </v-row>
      <br />
      <v-row dense class="ma-0 pa-0 mb-3" v-if="false">
        <v-col
          cols="12"
          class="
            d-flex
            flex-column flex-md-row
            justify-md-end justify-center
            align-center
          "
        >
          <span class="mr-2"> Opciones: </span>
          <v-chip
            v-for="(filterChip, index) in filters"
            :key="filterChip.index"
            :outlined="!filterChip.active"
            :class="{
              'ml-md-2': index > 0,
              'mt-2': !$vuetify.breakpoint.mdAndUp,
            }"
            @click="filterChip.active = !filterChip.active"
            class="d-flex flex-row justify-center align-center"
            color="primary"
          >
            <span class="">
              <v-simple-checkbox
                v-model="filterChip.active"
                :dark="filterChip.active"
              ></v-simple-checkbox>
            </span>
            {{ ES ? filterChip.nameES : filterChip.nameEN }}
          </v-chip>
        </v-col>
      </v-row>
      <!-- <v-divider></v-divider>
      <v-row
        dense
        class="ma-0 pa-0 d-flex flex-row justify-center justify-md-end mt-3"
      >
        <span class="font-weight-bold"> Nota: </span>
        <span class="pl-2">
          Las opciones de
          <span class="font-weight-bold"> recarga manual </span> deben ser
          validadas por un administrador
        </span>
      </v-row> -->
    </template>
    <template v-if="loadingCarrousel">
      <v-col class="d-flex flex-row justify-end align-center">
        <v-skeleton-loader
          v-for="index in 8"
          :key="index"
          type="avatar, text"
          class="mr-4"
        ></v-skeleton-loader>
      </v-col>
    </template>
  </v-row>
</template>
<script>
import axios from "@/plugins/axios";
import vuetify from "@/plugins/vuetify";

export default {
  name: "TopUps",
  data() {
    return {
      topUpOptions: {
        bs: [
          {
            _id: "bs-pago-movil",
            name: "Pago móvil",
            currency: "bs",
            type: "automated",
            svgIcon: require("../assets/payment-methods/pago-movil.svg"),
          },
        ],
        usd: [
          {
            _id: "usd-zelle",
            name: "Zelle",
            currency: "usd",
            type: "automated",
            svgIcon: require("../assets/payment-methods/zelle.svg"),
          },
        ],
      },
      loadingCarrousel: !false,
      filters: [
        {
          nameES: "Automatizado",
          nameEN: "Automated",
          active: true,
          id: "automated",
        },
        {
          nameES: "Manual",
          nameEN: "Manual",
          active: true,
          id: "manual",
        },
        {
          nameES: "En Bs",
          nameEN: "In Bs",
          active: true,
          id: "bs",
        },
        {
          nameES: "En USD",
          nameEN: "In USD",
          active: true,
          id: "usd",
        },
      ],
    };
  },
  methods: {
    async getManualPaymentMethods() {
      this.loadingCarrousel = true;
      try {
        const manualPaymentMethodsResponse = await axios.get(
          '/get_payments_manual'
        );
        if (!manualPaymentMethodsResponse?.data?.success) {
          throw new Error("Error getting ManualPaymentMethods");
        }

        const { payments = [] } = manualPaymentMethodsResponse.data;
        this.populatePaymentMethods(payments);
      } catch (error) {
        console.log(
          "🚀 ~ file: TopUps.vue:61 ~ getManualPaymentMethods ~ error:",
          error
        );
        this.$dialog.notify.error(error.message);
      } finally {
        this.loadingCarrousel = false;
      }
    },
    populatePaymentMethods(manualPayments = []) {
      manualPayments.forEach((payment) => {
        if (payment.is_available) {
          const {
            _id,
            name,
            icon_file_url = "",
            currency_code,
            detail = "",
            unique_id,
          } = payment;

          let payload = {
            _id,
            unique_id,
            name,
            currency: currency_code === "2" ? "bs" : "usd",
            type: "manual",
            svgIcon: icon_file_url,
            detail,
          };

          this.topUpOptions[payload.currency].push(payload);
        }
      });
    },
    handlePaymentMethodAvatar(paymentMethod) {
      if (!Boolean(paymentMethod.svgIcon)) {
        const TYPE_MAPPPING = {
          bs: require("../assets/payment-methods/base-bs.svg"),
          usd: require("../assets/payment-methods/currency-usd.svg"),
        };
        return TYPE_MAPPPING[paymentMethod.currency] || "";
      }
      return paymentMethod.svgIcon;
    },
    handleMethodClick(paymentMethod) {
      const methodMap = {
        "bs-pago-movil": "pagoMovilAutoClick",
        "usd-zelle": "zellelAutoClick",
      };

      const methodName = methodMap[paymentMethod._id];

      methodName
        ? this.$emit(methodName)
        : this.$emit("manualPaymentClick", paymentMethod);
    },
  },
  computed: {
    ES() {
      return this.$vuetify.lang.current === "es";
    },
    computedTopUpOptionsBs() {
      if (!this.filters.find((filter) => filter.id === "bs").active) {
        return [];
      }
      let filtered = [];

      if (this.filters.find((filter) => filter.id === "automated").active) {
        filtered = filtered.concat(
          this.topUpOptions.bs.filter((topUp) => topUp.type === "automated")
        );
      }

      if (this.filters.find((filter) => filter.id === "manual").active) {
        filtered = filtered.concat(
          this.topUpOptions.bs.filter((topUp) => topUp.type === "manual")
        );
      }

      return filtered;
    },
    computedTopUpOptionsUsd() {
      if (!this.filters.find((filter) => filter.id === "usd").active) {
        return [];
      }
      let filtered = [];

      if (this.filters.find((filter) => filter.id === "automated").active) {
        filtered = filtered.concat(
          this.topUpOptions.usd.filter((topUp) => topUp.type === "automated")
        );
      }

      if (this.filters.find((filter) => filter.id === "manual").active) {
        filtered = filtered.concat(
          this.topUpOptions.usd.filter((topUp) => topUp.type === "manual")
        );
      }

      return filtered;
    },
  },
  async created() {
    this.getManualPaymentMethods();
  },
};
</script>
<style lang="scss" scoped>
.payment-method {
  width: 100px;
  height: 100px;
  cursor: pointer;
  &-avatar {
    min-width: 60px;
    min-height: 60px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }
  &-currency {
    font-size: 10px;
  }
  &-name {
    font-size: 10px;
    text-align: center;
  }
}

// media queries

/* Extra small (xs) */
@media (max-width: 599px) {
  .payment-method {
    width: 100px;
    height: 100px;
    cursor: pointer;
    &-avatar {
      min-width: 50px;
      min-height: 50px;
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }
    &-currency {
      font-size: 10px;
    }
    &-name {
      font-size: 10px;
      text-align: center;
    }
  }
}

/* Small (sm) */
@media (min-width: 600px) and (max-width: 959px) {
  /* CSS rules for small devices */
}

/* Medium (md) */
@media (min-width: 960px) and (max-width: 1263px) {
  /* CSS rules for medium devices */
}

/* Large (lg) */
@media (min-width: 1264px) and (max-width: 1903px) {
  /* CSS rules for large devices */
}

/* Extra large (xl) */
@media (min-width: 1904px) {
  /* CSS rules for extra large devices */
}
</style>
