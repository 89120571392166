<template>
  <span>
    <v-data-table
      :loading="loading"
      :headers="computedHeaders"
      :disable-sort="true"
      :items="items"
      class="dataTable elevation-1"
      calculate-widths
      hide-default-footer
      disable-pagination
      fixed-header
      mobile-breakpoint="0"
      :height="computedHeight"
      :dense="true"
    >
      <template #top>
        <v-row dense>
          <v-col cols="12" class="d-flex flex-row align-center justify-end">
            <v-tooltip top :max-width="300">
              <template #activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  color="primary"
                  @click="handleRefresh()"
                >
                  <v-icon>
                    {{ icons.mdiRefresh }}
                  </v-icon>
                </v-btn>
              </template>
              <span>
                {{ $t("Refresh table") }}
              </span>
            </v-tooltip>
          </v-col>
        </v-row>
      </template>
      <template #item.add_cut="{ item }">
        <span
          :class="
            item.wallet_status === 2 ? `color-red-text` : `color-green-text`
          "
        >
          <span v-if="item.wallet_status === 2">
            {{ ("-" + item.added_wallet) | currency }}
          </span>
          <span v-else>
            {{ item.added_wallet | currency }}
          </span>
        </span>
      </template>
      <template #item.created_at="{ value }">
        {{ value | moment("DD-MM-YYYY h:mm a") }}
      </template>
      <template #item.wallet_amount="{ value }">
        {{ value | currency }}
      </template>
      <template #item.total_wallet_amount="{ value }">
        {{ value | currency }}
      </template>
      <template #item.wallet_description="{ item }">
        {{ handleOriginTranslate(item.wallet_description, item.wallet_status) }}
      </template>
    </v-data-table>
  </span>
</template>
<script>
import { mdiDotsVertical, mdiRefresh } from "@mdi/js";
export default {
  props: {
    loading: {
      type: Boolean,
      required: true,
      default: false,
    },
    page: {
      required: true,
    },
    headers: {
      required: true,
    },
    items: {
      required: true,
    },
  },
  data() {
    return {
      icons: {
        mdiDotsVertical,
        mdiRefresh,
      },
    };
  },
  computed: {
    computedHeight() {
      if (this.items.length > 9) {
        return 380;
      }
      if (this.items.length > 7) {
        return 300;
      }
      if (this.items.length > 5) {
        return 300;
      }
      if (this.items.length > 3) {
        return 250;
      }
      if (this.items.length > 1) {
        return 100;
      }
    },
    computedHeaders() {
      let headers = this.headers;
      headers.map((head) => (head.text = this.$t(head.text)));
      return headers;
    },
  },
  methods: {
    handleOriginTranslate(trip, status) {
      if (this.$vuetify.lang.current === "es") {
        trip = trip.replace("By Admin", "Por admin");
        trip = trip.replace(
          "User amount limit",
          status === 2
            ? "Monto restado del panel corporativo"
            : "Monto agregado del panel corporativo"
        );
        trip = trip.replace("Charge Of This Trip", "Cargo del viaje");
        trip = trip.replace("Card", "Tarjeta");
        trip = trip.replace("Charge Of This Parcel", "Cargo del envío");
        return trip;
      }
      return trip;
    },
    handleRefresh() {
      this.$emit("refresh");
    },
  },
};
</script>
<style lang="scss">
.dataTable {
  tbody tr:nth-of-type(even) {
    background-color: rgba(58, 53, 65, 0.04);
  }

  tbody tr:hover {
    background-color: rgba(0, 5, 34, 0.08) !important;
  }

  tbody tr {
    border-bottom: hidden !important;
  }
}
</style>
