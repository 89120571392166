<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        v-bind="$attrs"
        v-on="on"
        :value="date"
        autocomplete="off"
        @click:append="menu = true"
        @input="$emit('input', $event)"
        readonly
        dense
        outlined
        :append-icon="icons.mdiCalendar"
      >
        <template v-slot:append-outer v-if="tooltip">
          <v-tooltip bottom max-width="150">
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="primary" v-bind="attrs" v-on="on">
                {{ icons.mdiInformationOutline }}
              </v-icon>
            </template>
            <span >{{ tooltip.information }}</span>
          </v-tooltip>
        </template>
      </v-text-field>
    </template>
    <v-date-picker
      :value="date"
      locale="es-es"
      @input="handleInput"
      show-current
      :min="min"
      :max="max"
    />
  </v-menu>
</template>

<script>
import { mdiInformationOutline, mdiCalendar } from "@mdi/js";

export default {
  name: "DatePickerInput",
  props: {
    value: {
      type: [Date, String, Number],
      default: "",
    },
    appendIcon: {
      type: String,
      default: "mdi-calendar",
    },
    min: {
      type: String,
    },
    max: {
      type: String,
    },
    tooltip: {
      type: [Boolean, Object],
      default: false,
    },
  },
  data() {
    return {
      menu: false,
      icons: {
        mdiInformationOutline,
        mdiCalendar
      },
    };
  },
  computed: {
    date() {
      return this.value;
    },
  },
  methods: {
    handleInput(value) {
      this.menu = false;
      this.$emit("input", value);
    },
  },
};
</script>

<style></style>
