<template>
  <v-card tile class="pa-0">
    <!-- <v-card-title class="text-h5 primary mb-2">
      <span class="white--text">
        Notificación
      </span>
    </v-card-title> -->

    <v-card-text class="text-center pa-0">
      <v-container class="pa-12">
        <v-row dense class="d-flex flex-column justify-center align-center">
          <v-col cols="12">
            <h2>
              {{ ES ? "Recargas manuales" : "Manual top ups" }}
            </h2>
            <v-row
              dense
              class="d-flex flex-row justify-center align-center mt-1"
            >
              <v-col
                cols="3"
                class="ma-0 pa-0 d-flex flex-row justify-center align-center"
              >
                <div
                  class="d-flex flex-column justify-center align-center payment-method"
                >
                  <div
                    class="payment-method-avatar d-flex flex-row justify-center align-center background-green elevation-6"
                    :class="
                      manualTopUpForm.paymentOption.currency === 'bs'
                        ? 'backgroung-green'
                        : 'background-dark-blue'
                    "
                  >
                    <v-img
                      :src="
                        handlePaymentMethodAvatar(manualTopUpForm.paymentOption)
                      "
                      :max-width="$vuetify.breakpoint.mdAndUp ? 40 : 30"
                      :max-height="$vuetify.breakpoint.mdAndUp ? 40 : 30"
                      contain
                    >
                    </v-img>
                  </div>
                  <div
                    class="d-flex flex-row justify-center payment-method-name font-weight-bold mt-1"
                    style="width: 100%"
                  >
                    {{ manualTopUpForm.paymentOption.name }}
                  </div>
                  <div
                    class="d-flex flex-column flex-md-row justify-center payment-method-currency align-center"
                    style="width: 100px"
                  >
                    <span class="font-weight-bold">
                      {{
                        manualTopUpForm.paymentOption.currency === "bs"
                          ? "Bs"
                          : "USD"
                      }}
                    </span>
                  </div>
                </div>
              </v-col>
            </v-row>
            <p class="mt-1">
              {{
                ES
                  ? "Realiza tus recargas de balance corporativo con nuestro sistema de recargas manuales"
                  : "Make your manual top ups of corproate balance with our system"
              }}
            </p>
          </v-col>
        </v-row>
        <template>
          <v-stepper
            v-model="activeStep"
            class="custom-header pa-0 ma-0"
            elevation="0"
          >
            <!-- Header -->
            <v-stepper-header>
              <!-- Header: Step 1 -->
              <v-stepper-step
                :complete="activeStep > 1"
                step="1"
                :color="activeStep > 1 ? 'success' : 'error'"
              >
                <div class="d-flex align-center">
                  <span class="text--primary text-4xl font-weight-bold me-3"
                    >01</span
                  >
                  <div class="d-flex flex-column" v-if="activeStep === 1">
                    <span class="text--primary text-sm font-weight-semibold">
                      {{ ES ? "Monto a recargar" : "Amount to recharge" }}
                    </span>
                    <span class="text--secondary text-xs">
                      {{
                        ES
                          ? "Define el monto a recargar"
                          : "Set the amount to recharge"
                      }}
                    </span>
                  </div>
                </div>
              </v-stepper-step>

              <v-divider></v-divider>

              <!-- Header: Step 2 -->
              <v-stepper-step
                step="2"
                :complete="activeStep > 2"
                :color="activeStep > 2 ? 'success' : 'error'"
              >
                <div class="d-flex align-center">
                  <span class="text--primary text-4xl font-weight-bold me-3"
                    >02</span
                  >
                  <div class="d-flex flex-column" v-if="activeStep === 2">
                    <span class="text--primary text-sm font-weight-semibold">
                      {{ ES ? "Coordenadas de pago" : "Paymente coordinates" }}
                    </span>
                    <span class="text--secondary text-xs">
                      <span v-if="ES">
                        Estas son las coordenadas <br />para realizar su pago
                      </span>
                      <span v-else>
                        The are the coordinates <br />to make your payment
                      </span>
                    </span>
                  </div>
                </div>
              </v-stepper-step>

              <v-divider></v-divider>

              <!-- Header: Step 3 -->
              <v-stepper-step
                step="3"
                :complete="activeStep > 3"
                :color="activeStep > 3 ? 'success' : 'error'"
              >
                <div class="d-flex align-center">
                  <span class="text--primary text-4xl font-weight-bold me-3"
                    >03</span
                  >
                  <div class="d-flex flex-column" v-if="activeStep === 3">
                    <span class="text--primary text-sm font-weight-semibold">
                      {{
                        ES
                          ? "Enviar comprobante de pago"
                          : "Send your payment voucher"
                      }}
                    </span>
                    <span class="text--secondary text-xs">
                      <span v-if="ES">
                        Envía tu comprobante de pago <br />e información
                        relacionada para procesar
                      </span>
                      <span v-else>
                        Send your payment voucher
                        <br />
                        and payment information to process
                      </span>
                    </span>
                  </div>
                </div>
              </v-stepper-step>

              <v-divider></v-divider>

              <!-- Header: Step 4 -->
              <v-stepper-step
                step="4"
                :complete="activeStep === 4 && manualTopUpFormSuccess"
                :color="
                  activeStep === 4 && manualTopUpFormSuccess
                    ? 'success'
                    : 'error'
                "
              >
                <div class="d-flex align-center">
                  <span class="text--primary text-4xl font-weight-bold me-3"
                    >04</span
                  >
                  <div class="d-flex flex-column" v-if="activeStep === 4">
                    <span v-if="manualTopUpFormSuccess">
                      <span
                        class="text--primary text-sm font-weight-semibold"
                        >{{
                          ES ? "Gracias por tu pago" : "Thanks for your payment"
                        }}</span
                      >
                      <br />
                      <span class="text--secondary text-xs">{{
                        ES
                          ? "Tu pago será procesado próximamente"
                          : "Your payment will be processed shortly"
                      }}</span>
                    </span>
                    <span v-else>
                      <span
                        class="color-red-text text-sm font-weight-semibold"
                        >{{ ES ? "¡Algo pasó!" : "Something happened!" }}</span
                      >
                      <br />
                      <span class="text--secondary text-xs">{{
                        ES ? "Por favor intenta de nuevo" : "Please try again"
                      }}</span>
                    </span>
                  </div>
                </div>
              </v-stepper-step>
            </v-stepper-header>
            <!-- Stepper Items -->
            <v-stepper-items class="pa-0">
              <!-- Stepper Content: Step 1 -->
              <v-stepper-content step="1">
                <v-card class="pt-8" elevation="0">
                  <!-- <v-row dense justify="center">
                    <v-col md="3" cols="12">
                      <span class="text-lg text--primary">{{
                        ES
                          ? "Escoge la moneda de tu recarga"
                          : "Pick the currency of your top up"
                      }}</span>
                    </v-col>
                  </v-row>
                  <v-row dense justify="center">
                    <v-col md="3" cols="12">
                      <v-btn
                        :color="manualTopUpForm.currency && 'success'"
                        :outlined="!(manualTopUpForm.currency === '2')"
                        @click="manualTopUpForm.currency = '2'"
                        >Bs</v-btn
                      >
                      <v-btn
                        class="ml-md-8 ml-8"
                        :color="manualTopUpForm.currency && 'success'"
                        :outlined="!(manualTopUpForm.currency === '1')"
                        @click="manualTopUpForm.currency = '1'"
                        >Usd</v-btn
                      >
                    </v-col>
                  </v-row> -->
                  <v-row dense justify="center" class="mt-md-10 mt-8">
                    <v-col md="3" cols="12">
                      <v-text-field
                        :label="
                          ES ? 'Cantidad a agregar' : 'Amount to recharge'
                        "
                        outlined
                        dense
                        v-model.lazy="price"
                        v-money="money"
                        @keydown="nameKeydown($event)"
                        max-width="200"
                        :disabled="!manualTopUpForm.currency"
                        :prefix="handleMoneyPrefix()"
                        type="tel"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-card>

                <div class="d-flex justify-center">
                  <v-btn
                    color="primary"
                    @click="activeStep = 2"
                    :disabled="price <= 0"
                    :small="!$vuetify.breakpoint.mdAndUp"
                  >
                    {{ ES ? "Siguiente" : "Next" }}
                  </v-btn>
                </div>
              </v-stepper-content>
              <!-- Stepper Content: Step 2 -->
              <v-stepper-content step="2">
                <v-card class="pt-8 pb-8" elevation="0">
                  <v-row dense justify="center">
                    <v-col
                      cols="12"
                      class="d-flex justify-center align-start flex-column"
                    >
                      <p class="text-xl text--primary">
                        {{ ES ? "Monto:" : "Amount:" }}
                        <span class="font-weight-bold"
                          >{{ manualTopUpForm.currency === "2" ? "Bs" : "$" }}
                          {{ price }}</span
                        >
                      </p>
                      <p class="text-xl text--primary">
                        <span class="font-weight-bold">{{
                          manualTopUpForm.paymentOption.name
                        }}</span>
                      </p>
                      <p class="text-xl text--primary">
                        <span class=""
                          >{{ ES ? "Realiza tu pago" : "Make your payment" }}
                          <span class="font-weight-bold">{{
                            ES ? "Utilizando" : "using"
                          }}</span>
                          {{
                            ES
                              ? "las siguientes coordenadas e instrucciones:"
                              : "the following payment coordinates and instructions:"
                          }}</span
                        >
                      </p>
                    </v-col>
                    <v-divider
                      :color="!$vuetify.theme.isDark ? '#000522' : '#3BD4AE'"
                      class="mt-2 mb-6"
                    />
                  </v-row>
                  <v-row dense>
                    <v-col cols="12" class="d-flex justify-end">
                      <v-tooltip left v-model="showTooltipInstructionsCopy">
                        <template v-slot:activator="{ attrs }">
                          <v-btn
                            icon
                            @click="handleCopyClipboard"
                            v-bind="attrs"
                          >
                            <v-icon>
                              {{ icons.mdiContentCopy }}
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>{{
                          ES ? "Instrucciones copiadas" : "Instructions copied"
                        }}</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col
                      cols="12"
                      class="d-flex justify-start text-justify align-start flex-column font-weight-medium text-md-h5 font-weight-bold"
                    >
                      <div style="white-space: pre-wrap">
                        {{ manualTopUpForm.paymentOption.detail }}
                      </div>
                    </v-col>
                  </v-row>
                </v-card>
                <div class="d-flex justify-center" v-if="!loading">
                  <v-btn
                    outlined
                    color="primary"
                    @click="activeStep = 1"
                    :disabled="loading"
                    :small="!$vuetify.breakpoint.mdAndUp"
                  >
                    {{ ES ? "Volver" : "Back" }}
                  </v-btn>
                  <v-btn
                    class="ml-2"
                    color="primary"
                    @click="activeStep = 3"
                    :disabled="loading || invalid"
                    :small="!$vuetify.breakpoint.mdAndUp"
                  >
                    {{ ES ? "Siguiente" : "Next" }}
                  </v-btn>
                </div>
              </v-stepper-content>

              <v-stepper-content step="3">
                <ValidationObserver v-slot="{ invalid }">
                  <v-card class="pt-8 pb-8" elevation="0" v-show="!loading">
                    <!-- Datos bancarios -->
                    <v-row dense justify="center">
                      <v-col
                        cols="12"
                        class="d-flex justify-center align-start flex-column"
                      >
                        <p class="text-xl text--primary">
                          {{ ES ? "Monto:" : "Amount:" }}
                          <span class="font-weight-bold"
                            >{{
                              manualTopUpForm.currency === "2" ? "Bs" : "$"
                            }}
                            {{ price }}</span
                          >
                        </p>
                        <p class="text-xl text--primary">
                          <span class="font-weight-bold">{{
                            manualTopUpForm.paymentOption.name
                          }}</span>
                        </p>
                        <p class="text-xl text--primary">
                          {{
                            ES
                              ? "Envía tu comprobante de pago e información relacionada para procesar"
                              : "Send your payment voucher and payment information to process"
                          }}
                        </p>
                      </v-col>
                      <v-divider
                        :color="!$vuetify.theme.isDark ? '#000522' : '#3BD4AE'"
                        class="mt-2 mb-6"
                      />
                    </v-row>
                    <v-row dense class="d-flex justify-center">
                      <v-col cols="12" md="6">
                        <v-row dense>
                          <v-col cols="12">
                            <ValidationProvider
                              :name="ES ? 'referencia' : 'reference'"
                              rules="required|max:200"
                              v-slot="{ errors }"
                              slim
                            >
                              <v-text-field
                                :label="ES ? 'Referencia' : 'Reference'"
                                dense
                                outlined
                                v-model="manualTopUpForm.details.reference"
                                :error-messages="errors[0] && errors[0]"
                                clearable
                              >
                              </v-text-field>
                            </ValidationProvider>
                          </v-col>
                        </v-row>
                        <v-row dense>
                          <v-col>
                            <ValidationProvider
                              :name="ES ? 'fecha de pago' : 'payment date'"
                              rules="required"
                              v-slot="{ errors }"
                              slim
                            >
                              <DatePickerInput
                                :label="ES ? 'Fecha de pago' : 'Payment date'"
                                :placeholder="
                                  ES
                                    ? 'Ingrese su Fecha de pago'
                                    : 'Place your payment date'
                                "
                                v-model="manualTopUpForm.details.date"
                                outlined
                                dense
                                :max="maxDay"
                                :error-messages="errors[0] && errors[0]"
                              />
                            </ValidationProvider>
                          </v-col>
                        </v-row>
                        <v-row dense>
                          <v-col>
                            <ValidationProvider
                              :name="
                                ES
                                  ? 'correo a enviar factura'
                                  : 'email to send invoice'
                              "
                              rules="required|email|max:200"
                              v-slot="{ errors }"
                              slim
                            >
                              <v-text-field
                                :label="
                                  ES
                                    ? 'Correo a enviar factura'
                                    : 'Email to send invoice'
                                "
                                dense
                                outlined
                                v-model="manualTopUpForm.details.email"
                                :error-messages="errors[0] && errors[0]"
                                clearable
                              >
                              </v-text-field>
                            </ValidationProvider>
                          </v-col>
                        </v-row>
                        <v-row
                          dense
                          style="margin-top: -1px"
                          class="d-flex justify-center align-end"
                        >
                          <v-col class="d-flex justify-start">
                            <ValidationProvider
                              :name="
                                ES
                                  ? '¿Desea factura personalizada?'
                                  : 'Would you like a custom invoice?'
                              "
                              rules="required"
                              v-slot="{ errors }"
                              slim
                            >
                              <v-radio-group
                                v-model="manualTopUpForm.details.customInvoice"
                                :error-messages="errors[0] && errors[0]"
                                :label="
                                  ES
                                    ? '¿Desea factura personalizada?'
                                    : 'Would you like a custom invoice?'
                                "
                                dense
                                row
                              >
                                <v-radio
                                  :label="ES ? 'Sí' : 'Yes'"
                                  :value="true"
                                ></v-radio>
                                <v-radio label="No" :value="false"></v-radio>
                              </v-radio-group>
                            </ValidationProvider>
                          </v-col>
                        </v-row>
                        <v-row dense class="d-flex flex-row align-end">
                          <v-col cols="12" md="6" class="d-flex align-center">
                            <ValidationProvider
                              :name="
                                ES
                                  ? '¿Esta recarga corresponde a una proforma?'
                                  : 'This top up corresponds to a proforma?'
                              "
                              rules="required"
                              v-slot="{ errors }"
                              slim
                            >
                              <!-- v-model="manualTopUpForm.details.customInvoice" -->
                              <v-radio-group
                                :error-messages="errors[0] && errors[0]"
                                :label="
                                  ES
                                    ? '¿Esta recarga corresponde a una proforma?'
                                    : 'This top up corresponds to a proforma?'
                                "
                                dense
                                row
                                v-model="manualTopUpForm.details.proforma"
                              >
                                <v-radio
                                  :label="ES ? 'Sí' : 'Yes'"
                                  :value="true"
                                ></v-radio>
                                <v-radio label="No" :value="false"></v-radio>
                              </v-radio-group>
                            </ValidationProvider>
                          </v-col>
                          <v-col
                            cols="12"
                            md="6"
                            class="d-flex align-center"
                            v-if="manualTopUpForm.details.proforma"
                          >
                            <ValidationProvider
                              v-if="manualTopUpForm.details.proforma"
                              :name="'Número de proforma'"
                              rules="required"
                              v-slot="{ errors }"
                              slim
                            >
                              <v-text-field
                                v-model="manualTopUpForm.details.proformaNumber"
                                label="Proforma"
                                outlined
                                dense
                                :error-messages="errors[0] && errors[0]"
                              >
                              </v-text-field>
                            </ValidationProvider>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                        class="d-fle flex-column align-stretch pt-11"
                      >
                        <v-row dense>
                          <v-col
                            cols="12"
                            md="12"
                            class="d-flex flex-row justify-md-center justify-center"
                          >
                            <v-img
                              v-if="!url"
                              :src="uploadPlaceholder"
                              max-width="150"
                              @click="handleClickImage()"
                              class="hover-image rounded-lg"
                            ></v-img>
                            <!-- <v-img
                              v-else
                              :src="url && url"
                              max-width="150"
                              @click="handleClickImage2()"
                              class="hover-image rounded-lg"
                            ></v-img> -->

                            <v-dialog
                              v-else
                              v-model="dialog"
                              fullscreen
                              hide-overlay
                              transition="dialog-bottom-transition"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-img
                                  :src="url && url"
                                  width="150"
                                  height="150"
                                  v-bind="attrs"
                                  v-on="on"
                                  class="hover-image rounded-lg ml-md-4"
                                >
                                  <template v-slot:placeholder>
                                    <v-row
                                      class="fill-height ma-0"
                                      align="center"
                                      justify="center"
                                    >
                                      <v-progress-circular
                                        indeterminate
                                        color="primary"
                                      ></v-progress-circular>
                                    </v-row>
                                  </template>
                                </v-img>
                              </template>
                              <v-card class="">
                                <v-row dense>
                                  <v-col cols="12" class="">
                                    <v-toolbar
                                      color="primary"
                                      class="d-flex justify-space-between"
                                      tile
                                    >
                                      <span
                                        class="d-flex flex-row justify-space-between"
                                        style="width: 98vw"
                                      >
                                        <v-toolbar-title
                                          class="color-white-text"
                                          >{{
                                            ES
                                              ? "Comprobante de pago"
                                              : "Payment receipt"
                                          }}</v-toolbar-title
                                        >
                                        <v-btn
                                          icon
                                          dark
                                          @click="dialog = false"
                                        >
                                          <v-icon>{{ icons.mdiClose }}</v-icon>
                                        </v-btn>
                                      </span>
                                    </v-toolbar>
                                  </v-col>
                                </v-row>
                                <v-row dense>
                                  <v-col
                                    cols="12"
                                    class="d-flex align-center justify-center pa-12"
                                  >
                                    <v-img
                                      :src="url && url"
                                      class="modal-image"
                                      :max-width="
                                        !$vuetify.breakpoint.mdAndUp ? 280 : ''
                                      "
                                    >
                                      <template v-slot:placeholder>
                                        <v-row
                                          class="fill-height ma-0"
                                          align="center"
                                          justify="center"
                                        >
                                          <v-progress-circular
                                            indeterminate
                                            color="primary"
                                          ></v-progress-circular>
                                        </v-row>
                                      </template>
                                    </v-img>
                                  </v-col>
                                </v-row>
                              </v-card>
                            </v-dialog>
                          </v-col>
                        </v-row>
                        <v-row
                          dense
                          class="mt-2 d-flex justify-center align-center"
                        >
                          <v-col cols="12" md="12">
                            <br />
                            <ValidationProvider
                              :name="
                                ES ? 'comprobante de pago' : 'payment receipt'
                              "
                              rules="required"
                              v-slot="{ errors }"
                              slim
                              ><v-file-input
                                v-model="manualTopUpForm.details.paymentImage"
                                show-size
                                :truncate-length="
                                  $vuetify.breakpoint.mdAndUp ? 50 : 10
                                "
                                :label="
                                  ES
                                    ? 'Adjuntar comprobante de pago'
                                    : 'Attach your payment voucher'
                                "
                                :error-messages="errors[0] && errors[0]"
                                accept="image/png, image/jpg, image/jpeg"
                                dense
                                outlined
                                @change="previewImage()"
                                ref="file"
                              >
                              </v-file-input>
                            </ValidationProvider>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>

                    <!-- <br />
                    <v-divider></v-divider>
                    <br /> -->
                    <!-- datos -->
                  </v-card>
                  <v-card v-show="loading" class="pt-8 pb-8" elevation="0">
                    <v-progress-circular
                      class="mt-10 mb-10"
                      indeterminate
                      :size="64"
                      color="primary"
                    />
                    <p class="text-xl">
                      {{ ES ? "Enviando formulario..." : "Sending form" }}
                    </p>
                  </v-card>

                  <div class="d-flex justify-center" v-if="!loading">
                    <v-btn
                      outlined
                      color="primary"
                      @click="activeStep--"
                      :disabled="loading"
                      :small="!$vuetify.breakpoint.mdAndUp"
                    >
                      {{ ES ? "Volver" : "Back" }}
                    </v-btn>
                    <v-btn
                      class="ml-2"
                      color="primary"
                      @click="handleStepFour()"
                      :disabled="
                        loading ||
                        invalid ||
                        (manualTopUpForm.details.paymentImage &&
                          manualTopUpForm.details.paymentImage.size > 1000000)
                      "
                      :loading="loading"
                      :small="!$vuetify.breakpoint.mdAndUp"
                    >
                      {{ ES ? "Siguiente" : "Next" }}
                    </v-btn>
                  </div>
                </ValidationObserver>
              </v-stepper-content>
              <v-stepper-content step="4">
                <template v-if="manualTopUpFormSuccess">
                  <v-row dense>
                    <v-col cols="12" class="d-flex flex-column align-center">
                      <v-img
                        src="@/views/Balance/assets/found.png"
                        width="250"
                        class="wobble-hor-bottom"
                      >
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="primary"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                      <br />
                      <h2>{{ ES ? "¡Éxito!" : "Success!" }}</h2>
                      <h3 class="fontUrbanist">
                        {{
                          ES
                            ? "Tu recarga manual ha sido"
                            : "Your manual top up has"
                        }}
                        <span class="font-weight-bold">{{
                          ES ? "enviada" : "has been sent"
                        }}</span>
                        {{
                          ES
                            ? "a la base de datos de Ridery"
                            : "to Ridery's database"
                        }}
                      </h3>
                      <span>
                        <span>
                          {{
                            ES
                              ? "Próximamente validaremos tu recarga"
                              : "We will be validating your top up"
                          }}</span
                        >
                      </span>
                    </v-col>
                  </v-row>
                </template>
                <template v-else>
                  <v-row dense>
                    <v-col cols="12" class="d-flex flex-column align-center">
                      <v-img
                        src="@/views/Balance/assets/notFound.png"
                        width="250"
                        class="wobble-hor-bottom"
                      >
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="primary"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                      <br />
                      <h2>{{ ES ? "¡Algo pasó!" : "Something happened!" }}</h2>
                      <h3 class="fontUrbanist">
                        {{
                          ES
                            ? "Tu recarga manual no ha podido ser"
                            : "Your manual top up could not be"
                        }}
                        <span class="font-weight-bold">
                          {{ ES ? "enviada" : "sent" }}</span
                        >
                        {{
                          ES
                            ? "a la base de datos de Ridery"
                            : "to Ridery's databale"
                        }}
                      </h3>
                      <span>
                        <span>
                          {{
                            ES
                              ? "Por favor intenta de nuevo"
                              : "Please try again"
                          }}</span
                        >
                      </span>
                      <br />
                      <span>
                        <v-btn color="primary" @click="activeStep--">
                          {{ ES ? "Volver" : "Back" }}
                        </v-btn>
                      </span>
                    </v-col>
                  </v-row>
                </template>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </template>
        <v-row
          class="d-flex flex-md-row flex-column-reverse justify-center align-center"
        >
          <v-col cols="12" md="4" v-if="!loading">
            <a
              text
              block
              color="primary"
              class="text-decoration-underline text-lg"
              :class="!$vuetify.theme.isDark && 'color-blue-text'"
              @click="handleClose(manualTopUpFormSuccess)"
            >
              {{
                manualTopUpFormSuccess
                  ? ES
                    ? "Regresar"
                    : "Go back"
                  : ES
                  ? "Cancelar"
                  : "Cancel"
              }}
            </a>
          </v-col>
        </v-row>
        <v-divider class="mt-4"></v-divider>
        <v-row
          dense
          class="ma-0 pa-0 d-flex flex-row justify-center justify-md-center mt-3"
        >
          <template v-if="ES">
            <span class="font-weight-bold"> Nota: </span>
            <span class="pl-2">
              Esta
              <span class="font-weight-bold"> recarga </span> debe ser validada
              por un administrador
            </span>
          </template>
          <template v-else>
            <span class="font-weight-bold"> Note: </span>
            <span class="pl-2">
              This
              <span class="font-weight-bold"> top up </span> must be validated
              by an admin
            </span>
          </template>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>
<script>
import { mdiWhatsapp } from "@mdi/js";
import {
  mdiMagnify,
  mdiAlert,
  mdiContentCopy,
  mdiInformationOutline,
  mdiClose,
} from "@mdi/js";
import { mapActions, mapState } from "vuex";
import axios from "@/plugins/axios";
import { VMoney } from "v-money";
import PhoneInput from "@/components/PhoneInput";
import DatePickerInput from "@/components/DatePickerInput";
import { mask } from "vue-the-mask";

export default {
  props: {},
  components: {
    PhoneInput,
    DatePickerInput,
  },
  directives: { mask },
  data() {
    return {
      icons: {
        mdiWhatsapp,
        mdiMagnify,
        mdiAlert,
        mdiContentCopy,
        mdiInformationOutline,
        mdiClose,
      },
      invalid: false,
      loading: false,
      price: 0,
      money: {
        decimal: ".",
        thousands: ",",
        precision: 2,
      },
      activeStep: 1,
      manualTopUpFormSuccess: false,
      errorCode: null,
      manualTopUpForm: {
        currency: undefined,
        paymentOptions: [],
        paymentOption: undefined,
        details: {
          reference: "",
          date: "",
          email: "",
          customInvoice: undefined,
          paymentImage: null,
          proforma: undefined,
          proformaNumber: "",
        },
      },
      file: null,
      rules: [
        (value) =>
          !value ||
          (value && value.size < 1000000) ||
          (this.ES
            ? "El comprobante de pago debe ser menor a 1 mb"
            : "The payment voucher must be below 1 mb"),
      ],
      url: null,
      uploadPlaceholder: require("../assets/image-upload.png"),
      showTooltipInstructionsCopy: false,
      dialog: false,
    };
  },
  beforeDestroy() {
    clearTimeout(this._timerId);
  },
  created() {
    const { currency, detail, name, svgIcon, _id, unique_id } =
      this.$attrs.payload;
    console.log(this.$attrs.payload);

    this.manualTopUpForm.currency = currency === "bs" ? "2" : "1";
    this.manualTopUpForm.paymentOption = {
      _id,
      name,
      detail,
      svgIcon,
      unique_id,
      currency,
    };

    this.manualTopUpForm.details.email = this.email;
  },
  methods: {
    handleClose(success) {
      if (success) {
        this.$emit("submit", true);
      }
      this.$emit("submit", null);
    },

    nameKeydown(e) {
      if (/^\W$/.test(e.key)) {
        e.preventDefault();
      }
    },
    handleCopyClipboard() {
      navigator.clipboard.writeText(this.computedSelectedPaymentOption.detail);
      this.showTooltipInstructionsCopy = true;
      this._timerId = setTimeout(
        () => (this.showTooltipInstructionsCopy = false),
        800
      );
    },
    handleFilterAutocompleteBanks(_item, _queryText, _itemText) {
      const searchText = _queryText.toLowerCase();

      const name = _item.name.toLowerCase();
      const code = _item.code.toLowerCase();

      return name.indexOf(searchText) > -1 || code.indexOf(searchText) > -1;
    },
    handleMoneyPrefix() {
      if (this.manualTopUpForm.currency === "2") {
        return "Bs";
      }
      if (this.manualTopUpForm.currency === "1") {
        return "$";
      }
      return "";
    },
    handlePaymentOptionsSelect() {
      let filtereBySelectedCurrency =
        this.manualTopUpForm.paymentOptions.filter(
          (payment) => payment.currency_code === this.manualTopUpForm.currency
        );
      filtereBySelectedCurrency.sort((a, b) => {
        if (a.name > b.name) {
          return 1;
        }
        if (b.name > a.name) {
          return -1;
        }
        return 0;
      });
      return filtereBySelectedCurrency;
    },
    previewImage(e) {
      if (!this.manualTopUpForm.details.paymentImage) {
        return;
      }

      let error = undefined;
      // nulo
      if (!this.manualTopUpForm.details.paymentImage.size) {
        this.manualTopUpForm.details.paymentImage = null;
        return;
      }

      // Menor a 5mb
      if (this.manualTopUpForm.details.paymentImage.size) {
        const fileSize =
          this.manualTopUpForm.details.paymentImage.size / 1024 / 1024; //mb
        if (fileSize > 1) {
          this.$dialog.notify.error(
            this.ES
              ? "<h3>El archivo debe ser menor a 1mb </h3>"
              : "<h3>The file must be lower than 1mb </h3>",
            {
              position: "top-right",
              timeout: 2000,
            }
          );
          error = true;
        }
      }

      // tipos de archivo jpg o png
      let types = ["image/jpg", "image/jpeg", "image/png"];

      if (!types.includes(this.manualTopUpForm.details.paymentImage.type)) {
        this.$dialog.notify.error(
          this.ES
            ? "<h3>El archivo debe ser jpg, jpeg o png </h3>"
            : "<h3>The file must be jpg, jpeg or png </h3>",
          {
            position: "top-right",
            timeout: 2000,
          }
        );
        error = true;
      }

      if (error) {
        this.$nextTick(() => {
          this.manualTopUpForm.details.paymentImage = null;
          this.url = null;
        });
      } else {
        this.url = URL.createObjectURL(
          this.manualTopUpForm.details.paymentImage
        );
      }
    },
    handleClickImage() {
      this.$refs.file.$refs.input.click();
    },
    handleClickImage2() {
      alert();
    },
    async handleStepFour() {
      this.loading = true;
      const { currency, details } = this.manualTopUpForm;
      let formData = new FormData();
      formData.append("file", details.paymentImage, details.paymentImage.name);
      let params = {
        currency_id: parseInt(currency, 10),
        payment_name: this.computedSelectedPaymentOption.name,
        payment_manual_unique_id: this.computedSelectedPaymentOption.unique_id,
        amount: this.computedPrice,
        reference: details.reference,
        payment_at: details.date,
        email_sender: details.email,
        invoice_personalized: details.customInvoice,
        corporate_id: this._id,
        corporate_unique_id: this.unique_id,
        proforma: details.proforma ? details.proformaNumber : "",
      };
      // formData.append(params);
      for (var key in params) {
        formData.append(key, params[key]);
      }
      try {
        const { data } = await axios.post("/add_payment_transaction", formData);
        if (!!data.success) {
          this.manualTopUpFormSuccess = true;
        } else {
          if (data.message === "Bad Request") {
            this.manualTopUpFormSuccess = false;
          } else {
            this.manualTopUpFormSuccess = false;
            throw new Error("Error add_payment_transaction");
          }
        }
      } catch (error) {
        this.$dialog.notify.error(error.message);
      }
      this.loading = false;
      this.activeStep = 4;
    },
    handlePaymentMethodAvatar(paymentMethod) {
      console.log(
        "🚀 ~ file: AddManualTopUpModal.vue:1081 ~ handlePaymentMethodAvatar ~ paymentMethod:",
        paymentMethod
      );
      if (!Boolean(paymentMethod.svgIcon)) {
        const TYPE_MAPPPING = {
          bs: require("../assets/payment-methods/base-bs.svg"),
          usd: require("../assets/payment-methods/currency-usd.svg"),
        };
        return TYPE_MAPPPING[paymentMethod.currency] || "";
      }
      return paymentMethod.svgIcon;
    },
  },
  computed: {
    ...mapState("auth", ["email", "_id", "unique_id"]),

    computedPrice() {
      if (this.price.length >= 8) {
        return parseFloat(this.price.replace(/,/g, ""));
      } else {
        return parseFloat(this.price);
      }
    },
    computedBsTransfer() {
      return parseFloat(this.computedPrice * this.currencyRate).toFixed(2);
    },
    maxDay() {
      let today = new Date();
      return this.$moment(today).add(-4, "hours").toISOString();
    },
    computedSelectedPaymentOption() {
      return this.manualTopUpForm.paymentOption;
      // let selectedPaymentOptionName =
      //   this.manualTopUpForm.paymentOptions.filter(
      //     (payment) => payment._id === this.manualTopUpForm.paymentOption
      //   );
      // return selectedPaymentOptionName[0];
    },
    ES() {
      return this.$vuetify.lang.current === "es";
    },
  },
  watch: {
    activeStep: async function (newVal, oldVal) {
      // console.log(this.computedSelectedPaymentOption);
      // Paso 2 desde Paso 1
      // if (newVal === 2 && oldVal === 1) {
      //   this.loading = true;
      //   try {
      //     const { data } = await axios.get("/get_payments_manual");
      //     if (!!data.success) {
      //       this.manualTopUpForm.paymentOptions = data.payments;
      //     } else {
      //       throw new Error("Error getting Manual Payments");
      //     }
      //   } catch (error) {
      //     this.$dialog.notify.error(error.message);
      //   }
      //   this.loading = false;
      // }
    },
    "manualTopUpForm.details.paymentImage": function (newVal) {
      if (!newVal) {
        this.url = null;
      }
    },
  },
};
</script>
<style lang="sass" scoped>
.vuedl-layout__closeBtn
  color: rgba(58, 53, 65, 0.54)
    // color: white
    // opacity: 1
    // margin-top: 8px

.v-dialog.vuedl-layout.v-dialog--active.v-dialog--fullscreen
  .v-card.v-sheet.theme--light.rounded-0
    min-height: 100vh

a
  text-decoration: none

// .custom-margin
  // margin-top: -10px !important

// .test.v-btn:not(.v-btn--round).v-size--x-large
//   height: 55.22px !important

.custom-width
  width: 46%

.custom-margin-top
  margin-top: -10px

.hover-image:hover
  cursor: pointer

.modal-image
  // max-width: 80vw
</style>

<style lang="scss" scoped>
.payment-method {
  width: 100px;
  height: 100px;
  &-avatar {
    min-width: 60px;
    min-height: 60px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }
  &-currency {
    font-size: 10px;
  }
  &-name {
    font-size: 10px;
    text-align: center;
  }
}
</style>
