<template>
  <div class="d-flex flex-column justify-content-between">
    <v-row dense>
      <v-col cols="12">
        <v-card class="pa-5 d-flex flex-row justify-md-start justify-center">
          <v-row class="ma-0 pa-0 d-flex flex-row align-center" dense>
            <v-col
              cols="6"
              class="ma-0 pa-0 d-flex flex-row align-center justify-start"
            >
              <h2>Balance</h2>
              <v-tooltip top :max-width="300">
                <template #activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    medium
                    v-on="on"
                    class="ml-md-6 ml-2 color-gray-text"
                  >
                    {{ icons.mdiInformationOutline }}
                  </v-icon>
                </template>
                <span>{{ $t("tooltipBalance") }}</span>
              </v-tooltip>
            </v-col>
            <v-col
              cols="6"
              class="ma-0 pa-0 d-flex flex-row align-center justify-end"
              :class="computedColor"
            >
              <span>
                <v-icon
                  :size="$vuetify.breakpoint.mdAndUp ? 50 : 40"
                  :style="computedIconStyle"
                >
                  {{ icons.mdiWallet }}
                </v-icon>
              </span>
              <span class="d-flex flex-column">
                <span class="font-weight-bold text-md-h4 text-h6">
                  {{ wallet | currency }}
                </span>
                <span class="text-md-h5 ml-1 text-subtitle-2">
                  {{ $t("Available balance") }}
                </span>
              </span>
              <!-- <span class="ml-12">
                <v-icon size="90" :style="computedIconStyle">
                  {{ icons.mdiWalletPlus }}
                </v-icon>
              </span>
              <span class="d-flex flex-column">
                <span class="font-weight-bold custom-font-size">
                  ${{ overdraft }}
                </span>
                <span class="custom-font-size-small custom-margin">
                  Sobregiro
                </span>
              </span> -->
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row dense class="mt-5">
      <v-col cols="12">
        <v-card :class="['pa-5']">
          <v-row class="ma-0 pa-0 d-flex flex-row align-center" dense>
            <v-col
              md="6"
              cols="12"
              class="ma-0 pa-0 d-flex flex-row align-center justify-start"
            >
              <h2>{{ $t("topUps") }}</h2>
              <v-tooltip top :max-width="300">
                <template #activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    medium
                    v-on="on"
                    class="ml-md-6 ml-2 color-gray-text"
                  >
                    {{ icons.mdiInformationOutline }}
                  </v-icon>
                </template>
                <span>{{ $t("tooltipTopUps") }}</span>
              </v-tooltip>
              <span class="ml-5 color-red-text">
                {{ $vuetify.lang.current === "es" ? "Nuevas" : "New" }}
              </span>
            </v-col>
          </v-row>
          <TopUps
            @pagoMovilAutoClick="handlePagoMovilAutomatizadoModal()"
            @zellelAutoClick="handleZelleModal()"
            @manualPaymentClick="handleEmitManualPayment"
          />
        </v-card>
      </v-col>
    </v-row>

    <v-row dense class="mt-5">
      <v-col cols="12">
        <v-card class="pa-5">
          <v-row dense>
            <v-col cols="12" md="4">
              <v-select
                :label="$t('Transaction origin')"
                outlined
                dense
                clearable
                :items="
                  is_active_shipments
                    ? [
                        `${$t('By admin')}`,
                        `${$t('User amount')}`,
                        `${$t('Charge of this trip')}`,
                        `${$t('Card')}`,
                        `${$t('Charge of this parcel')}`,
                        `${$t('Pago móvil automatizado')}`,
                        `${$t('automated_zelle')}`,
                        `${$t('manual_top_up_request')}`,
                        `${$t('corporate_master_assign')}`,
                      ]
                    : [
                        `${$t('By admin')}`,
                        `${$t('User amount')}`,
                        `${$t('Charge of this trip')}`,
                        `${$t('Card')}`,
                        `${$t('Pago móvil automatizado')}`,
                        `${$t('automated_zelle')}`,
                        `${$t('manual_top_up_request')}`,
                        `${$t('corporate_master_assign')}`,
                      ]
                "
                v-model="table.searchItem"
                :disabled="table.tableLoading"
              ></v-select>
            </v-col>
            <v-col cols="12" md="4">
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="date"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date"
                    :label="$t('Date range')"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    dense
                    outlined
                    clearable
                    :append-icon="icons.mdiCalendar"
                    :disabled="table.tableLoading"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date"
                  range
                  no-title
                  scrollable
                  :max="computedMaxDate"
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu = false">
                    {{ $t("Cancel") }}
                  </v-btn>
                  <v-btn text color="primary" @click="$refs.menu.save(date)">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="2">
              <v-btn
                block
                color="primary"
                @click="
                  getCorporatePaymentsFilter() && getPendingManualPayments()
                "
                :disabled="table.tableLoading"
              >
                {{ $t("Apply") }}</v-btn
              ></v-col
            >
            <v-col
              cols="12"
              md="2"
              :class="!$vuetify.breakpoint.mdAndUp && 'mt-5'"
            >
              <v-btn
                block
                outlined
                color="primary"
                @click="getCorporatePaymentsExcelFilter()"
                :disabled="table.tableLoading"
              >
                <v-icon> {{ icons.mdiDownload }}</v-icon>
                <span class="ml-2">{{ $t("Export") }}</span>
              </v-btn></v-col
            >
          </v-row>
          <!-- tabla -->
          <v-row dense class="mt-4">
            <v-col cols="12">
              <DataTable
                :loading="table.tableLoading"
                :headers="table.headers"
                :items="PendingManualPayments"
                :page="table.currentPage"
                v-if="!new_"
                @refresh="handleRefreshTables()"
              />
            </v-col>
          </v-row>
          <v-row
            dense
            v-if="!new_"
            class="d-flex flex-row justify-space-between align-center"
          >
            <v-col
              cols="12"
              md="4"
              class="d-flex flex-row justify-end align-center pt-8"
            >
              <v-select
                :label="$t('Results per page')"
                :items="[5, 10, 20, 30]"
                v-model="table.limit"
                :disabled="table.tableLoading"
                dense
                outlined
              >
              </v-select>
              <v-autocomplete
                :class="!$vuetify.mdAndUp && 'ml-2'"
                :label="$t('Go to page')"
                :items="Array.from(Array(table.pages + 1).keys()).slice(1)"
                v-model="table.currentPage"
                :disabled="table.tableLoading"
                dense
                outlined
                @input="table.currentPage && handlePageChange()"
              >
              </v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              md="6"
              class="d-flex flex-row justify-md-end justify-center align-start"
            >
              <!-- <code>currentPage: {{ table.currentPage }}</code>
              <code>Pages: {{ table.pages }}</code>
              <code>ComputedPage: {{ computedPage }}</code> -->
              <v-pagination
                v-model="table.currentPage"
                :length="table.pages"
                total-visible="12"
                @input="handlePageChange()"
                :disabled="table.tableLoading"
              ></v-pagination>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <!-- Placeholder de nuevos -->
    <v-row v-if="new_" class="custom-margin-2">
      <v-col cols="12" class="d-flex flex-column justify-center align-center">
        <v-img
          src="../Balance/assets/typography_es.png"
          width="350"
          v-if="!$vuetify.theme.isDark"
        ></v-img>
        <v-img
          src="../Balance/assets/typography_es_dark.png"
          width="350"
          v-else
        ></v-img>
      </v-col>
    </v-row>

    <!-- <v-row>
      <v-col cols="1">
        <v-switch v-model="new_" label="nuevo" />
      </v-col>
    </v-row> -->

    <!-- Recargas manuales pendientes -->

    <v-row v-if="!pendingManualTopUpsHidden">
      <v-col cols="12">
        <v-card class="pa-5">
          <v-row dense>
            <v-col cols="9" md="6" class="d-flex flex-row align-center">
              <h2 :style="!$vuetify.breakpoint.mdAndUp && 'font-size: 0.8rem;'">
                {{ $t("manual_top_up_pending_table") }}
                {{ ` (${pendingValidationTable.items.length})` }}
              </h2>
              <v-tooltip top :max-width="300">
                <template #activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    medium
                    v-on="on"
                    class="ml-6 color-gray-text"
                  >
                    {{ icons.mdiInformationOutline }}
                  </v-icon>
                </template>
                <span>{{ $t("manual_top_up_pending_table_tooltip") }}</span>
              </v-tooltip>
            </v-col>
            <v-col cols="3" md="6" class="d-flex flex-row justify-end">
              <v-btn
                icon
                @click="
                  pendingManualTopUpsHiddenTable =
                    !pendingManualTopUpsHiddenTable
                "
                class="mb-1"
              >
                <v-icon large>
                  {{
                    pendingManualTopUpsHiddenTable
                      ? icons.mdiToggleSwitchOffOutline
                      : icons.mdiToggleSwitchOutline
                  }}
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row dense class="mt-4" v-if="!pendingManualTopUpsHiddenTable">
            <v-col cols="12">
              <DataTablePendingManualPayments
                :loading="pendingValidationTable.loading"
                :headers="pendingValidationTable.headers"
                :items="computedTablePendingManualPayments"
                :page="table.currentPage"
                @refresh="handleRefreshTables()"
              />
            </v-col>
          </v-row>
          <v-row
            dense
            v-if="!new_"
            class="d-flex flex-row justify-space-between align-center"
          >
            <v-col
              cols="12"
              md="4"
              class="d-flex flex-row justify-end align-center pt-8"
            >
              <v-select
                :label="$t('Results per page')"
                :items="[5, 10, 20, 30]"
                v-model="pendingValidationTable.limit"
                :disabled="pendingValidationTable.loading"
                dense
                outlined
              >
              </v-select>
              <v-autocomplete
                :class="!$vuetify.mdAndUp && 'ml-2'"
                :label="$t('Go to page')"
                :items="
                  Array.from(
                    Array(pendingValidationTable.pages + 1).keys()
                  ).slice(1)
                "
                v-model="pendingValidationTable.currentPage"
                :disabled="pendingValidationTable.loading"
                dense
                outlined
                @input="
                  pendingValidationTable.currentPage &&
                    handlePendingTablePageChange()
                "
              >
              </v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              md="6"
              class="d-flex flex-row justify-md-end justify-center align-start"
              ><v-pagination
                v-model="pendingValidationTable.currentPage"
                :length="pendingValidationTable.pages"
                total-visible="12"
                @input="handlePendingTablePageChange()"
                :disabled="pendingValidationTable.loading"
              ></v-pagination>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import {
  mdiInformationOutline,
  mdiMagnify,
  mdiCalendar,
  mdiDotsVertical,
  mdiDownload,
  mdiPlus,
  mdiWallet,
  mdiWalletPlus,
  mdiChevronDown,
  mdiToggleSwitchOffOutline,
  mdiToggleSwitchOutline,
} from "@mdi/js";
import axios from "@/plugins/axios";
import { mapActions, mapState } from "vuex";

import generateSocketIdMixin from "@/mixins/generateSocketIdMixin";
import ExportSocketDialog from "../../components/ExportSocketDialog.vue";

import DataTable from "./parts/DataTable.vue";
import AddPagoMovilAutomatizadoModal from "./parts/AddPagoMovilAutomatizadoModal";
import AddPagoMovilAutomatizadoModalEN from "./parts/AddPagoMovilAutomatizadoModalEN";
import AddManualTopUpModal from "./parts/AddManualTopUpModal";
import AddZelleModal from "./parts/AddZelleModal";
import AddZelleModalEN from "./parts/AddZelleModalEN";
import DataTablePendingManualPayments from "./parts/DataTablePendingManualPayments.vue";
import TopUps from "./parts/TopUps.vue";
import ProformModal from "./parts/ProformModal.vue";

export default {
  name: "Balance",
  mixins: [generateSocketIdMixin],
  components: {
    DataTable,
    AddPagoMovilAutomatizadoModal,
    AddPagoMovilAutomatizadoModalEN,
    AddManualTopUpModal,
    AddZelleModal,
    AddZelleModalEN,
    DataTablePendingManualPayments,
    TopUps,
    ExportSocketDialog,
  },
  data() {
    return {
      icons: {
        mdiInformationOutline,
        mdiMagnify,
        mdiCalendar,
        mdiDotsVertical,
        mdiDownload,
        mdiPlus,
        mdiWallet,
        mdiWalletPlus,
        mdiChevronDown,
        mdiToggleSwitchOffOutline,
        mdiToggleSwitchOutline,
      },
      date: [],
      menu: false,
      new_: false,
      table: {
        movements: [],
        tableLoading: false,
        headers: [
          { text: "ID", value: "unique_id", align: "start" },
          { text: "FECHA Y HORA", value: "created_at", align: "start" },
          { text: "BALANCE INICIAL", value: "wallet_amount", align: "end" },
          { text: "AGREGADO/RESTADO", value: "add_cut", align: "end" },

          {
            text: "BALANCE FINAL",
            value: "total_wallet_amount",
            align: "end",
          },
          {
            text: "ORIGEN DE TRANSACCIÓN",
            value: "wallet_description",
            align: "start",
          },
          {
            text: "NOTA",
            value: "add_wallet_comment",
            align: "start",
          },
        ],
        currentPage: 1,
        pages: 1,
        searchItem: null,
        limit: this.$vuetify.breakpoint.mdAndUp ? 10 : 5,
        search: "",
        iconDown: true,
      },
      pendingManualTopUpsHidden: true,
      pendingManualTopUpsHiddenTable: false,
      pendingValidationTable: {
        headers: [
          { text: "ID", value: "unique_id", align: "start" },
          { text: "FECHA DE RECARGA", value: "payment_at", align: "start" },
          { text: "FECHA ENVIADO", value: "created_at", align: "start" },
          { text: "TIPO", value: "payment_name", align: "start" },
          { text: "MONEDA", value: "currency_id", align: "start" },
          { text: "MONTO", value: "amount", align: "end" },
          {
            text: "FACTURA PERSONALIZADA",
            value: "invoice_personalized",
            align: "center",
          },
          {
            text: "ACCIÓN",
            value: "actions",
            sortable: false,
            align: "center",
          },
        ],
        currentPage: 1,
        pages: 1,
        limit: this.$vuetify.breakpoint.mdAndUp ? 10 : 5,
        items: [],
        loading: false,
      },
    };
  },
  methods: {
    ...mapActions("auth", ["meNotLoading"]),
    ...mapActions(["setLoading"]),
    ...mapActions("showProformModal", ["updateDontShowAgain"]),
    async getCorporatePayments() {
      this.table.tableLoading = true;
      let params = {
        page: this.computedPage,
        limit: this.table.limit,
      };
      if (this.table.searchItem) {
        let search_item = "";
        if (this.table.searchItem === this.$t("By admin")) {
          search_item = 1;
        }
        if (this.table.searchItem === this.$t("User amount")) {
          search_item = 2;
        }
        if (this.table.searchItem === this.$t("Charge of this trip")) {
          search_item = 3;
        }
        if (this.table.searchItem === this.$t("Card")) {
          search_item = 4;
        }
        if (this.table.searchItem === this.$t("Charge of this parcel")) {
          search_item = 5;
        }
        if (this.table.searchItem === this.$t("Pago móvil automatizado")) {
          search_item = 6;
        }
        if (this.table.searchItem === this.$t("automated_zelle")) {
          search_item = 7;
        }
        if (this.table.searchItem === this.$t("manual_top_up_request")) {
          search_item = 8;
        }
        if (this.table.searchItem === this.$t("corporate_master_assign")) {
          search_item = 9;
        }
        params["transaction_type"] = search_item;
      }
      if (this.date) {
        if (this.date.length >= 1) {
          let sorted = this.date.sort();
          params["start_date"] = `${sorted[0]}`;
          if (sorted.length === 1) {
            params["end_date"] = `${sorted[0]}`;
          }
          if (sorted.length > 1) {
            params["end_date"] = `${sorted[1]}`;
          }
        }
      }
      try {
        const { data } = await axios.get(
          `${process.env.VUE_APP_STAGING}/v2/corporate/payments`,
          { params: params }
        );
        if (data?.success) {
          this.table.movements = [];
          data.data.detail.forEach((trip) => {
            // trip = this.handleOriginTranslate(trip);
            this.table.movements.push(trip);
          });
          this.table.pages = data.data.pages;
          this.table.currentPage = parseFloat(data.data.current_page);
          if (data.data.detail.length >= 1) {
            this.new_ = false;
          } else {
            this.new_ = true;
          }
        }
      } catch (error) {
        this.$dialog.notify.error(error.message);
      }
      this.meNotLoading();

      this.table.tableLoading = false;
    },
    async getCorporatePaymentsFilter() {
      this.table.currentPage = 1;
      this.table.tableLoading = true;
      let params = {
        page: 1,
        limit: this.table.limit,
      };
      if (this.table.searchItem) {
        let search_item = "";
        if (this.table.searchItem === this.$t("By admin")) {
          search_item = 1;
        }
        if (this.table.searchItem === this.$t("User amount")) {
          search_item = 2;
        }
        if (this.table.searchItem === this.$t("Charge of this trip")) {
          search_item = 3;
        }
        if (this.table.searchItem === this.$t("Card")) {
          search_item = 4;
        }
        if (this.table.searchItem === this.$t("Charge of this parcel")) {
          search_item = 5;
        }
        if (this.table.searchItem === this.$t("Pago móvil automatizado")) {
          search_item = 6;
        }
        if (this.table.searchItem === this.$t("automated_zelle")) {
          search_item = 7;
        }
        if (this.table.searchItem === this.$t("manual_top_up_request")) {
          search_item = 8;
        }
        if (this.table.searchItem === this.$t("corporate_master_assign")) {
          search_item = 9;
        }
        params["transaction_type"] = search_item;
      }
      if (this.date) {
        if (this.date.length >= 1) {
          let sorted = this.date.sort();
          params["start_date"] = `${sorted[0]}`;
          if (sorted.length === 1) {
            params["end_date"] = `${sorted[0]}`;
          }
          if (sorted.length > 1) {
            params["end_date"] = `${sorted[1]}`;
          }
        }
      }
      try {
        const { data } = await axios.get(
          `${process.env.VUE_APP_STAGING}/v2/corporate/payments`,
          {
            params: params,
          }
        );
        if (!!data.success) {
          this.table.movements = [];
          data.data.detail.forEach((trip) => {
            // trip = this.handleOriginTranslate(trip);
            this.table.movements.push(trip);
          });
          this.table.pages = data.data.pages;
          this.table.currentPage = parseFloat(data.data.current_page);
          if (data.data.detail.length >= 1) {
            this.new_ = false;
          } else {
            this.new_ = true;
          }
        }
      } catch (error) {
        this.$dialog.notify.error(error.message);
      }
      this.meNotLoading();

      this.table.tableLoading = false;
    },
    async getCorporatePaymentsExcelFilter() {
      try {
        const socketId = this.generateSocketId("providersExport");
        let queryParams = {};
        if (this.table.searchItem) {
          let search_item = "";
          if (this.table.searchItem === this.$t("By admin")) {
            search_item = 1;
          }
          if (this.table.searchItem === this.$t("User amount")) {
            search_item = 2;
          }
          if (this.table.searchItem === this.$t("Charge of this trip")) {
            search_item = 3;
          }
          if (this.table.searchItem === this.$t("Card")) {
            search_item = 4;
          }
          if (this.table.searchItem === this.$t("Charge of this parcel")) {
            search_item = 5;
          }
          if (this.table.searchItem === this.$t("Pago móvil automatizado")) {
            search_item = 6;
          }
          if (this.table.searchItem === this.$t("automated_zelle")) {
            search_item = 7;
          }
          if (this.table.searchItem === this.$t("manual_top_up_request")) {
            search_item = 8;
          }
          if (this.table.searchItem === this.$t("corporate_master_assign")) {
            search_item = 9;
          }
          queryParams["transaction_type"] = search_item;
        }
        if (this.date) {
          if (this.date.length >= 1) {
            let sorted = this.date.sort();
            queryParams["start_date"] = `${sorted[0]}`;
            if (sorted.length === 1) {
              queryParams["end_date"] = `${sorted[0]}`;
            }
            if (sorted.length > 1) {
              queryParams["end_date"] = `${sorted[1]}`;
            }
          }
        }
        queryParams["socketId"] = socketId;
        axios
          .get(`${process.env.VUE_APP_STAGING}/v2/corporate/payments/export`, {
            params: queryParams,
            timeout: 1800000, // 30 minutes in milliseconds
          })
          .then((response) => {
            console.log(
              "🚩🚩🚩- DM ~ ./V2/corporate/payments/export ~ response:",
              response
            );
          })
          .catch((error) => {
            console.log("🚩🚩🚩- DM ~ handleExport ~ error:", error);
            this.$dialog.notify.error(error?.message || "Ha ocurrido un error");
            throw new Error("Error en request");
          });

        const payload = {
          socketId,
        };

        const dialogParams = {
          fullscreen: !this.$vuetify.breakpoint.lgAndUp,
          width: "40%",
          height: "100%",
          transition: "dialog-top-transition",
          payload: payload,
          scrollable: true,
          showClose: false,
          dialogTitle: this.$t("Exporting payments"),
          processingMessage: this.$t("Processing page"),
          ofMessage: this.$t("of"),
          downloadMessage: this.$t("Download"),
          goBackMessage: this.$t("GoBack"),
          expiredMessage: this.$t("expired"),
          youCanDownloadMessage: this.$t("You can download the export file"),
          exportFileHasExpiredMessage: this.$t("The export file has"),
          processingPageMessage: this.$t("Processing pages for Excel file"),
          errorMessage: this.$t("Sorry, there was an error"),
          closeMessage: this.$t("Close"),
        };

        const dialogPromise = await this.$dialog.showAndWait(
          ExportSocketDialog,
          dialogParams
        );

        if (dialogPromise === "closed") {
          console.log("closed");
        }
      } catch (error) {
        this.$dialog.notify.error(error.message);
      }
    },
    handlePageChange() {
      this.getCorporatePayments();
    },
    handlePendingTablePageChange() {
      this.getPendingManualPayments();
    },
    handleOriginTranslate(trip) {
      trip.wallet_description = trip.wallet_description.replace(
        "By Admin",
        "Por admin"
      );
      trip.wallet_description = trip.wallet_description.replace(
        "User amount limit",
        trip.wallet_status === 2
          ? "Monto restado del panel corporativo"
          : "Monto agregado del panel corporativo"
      );
      trip.wallet_description = trip.wallet_description.replace(
        "Charge Of This Trip",
        "Cargo del viaje"
      );
      trip.wallet_description = trip.wallet_description.replace(
        "Card",
        "Tarjeta"
      );
      trip.wallet_description = trip.wallet_description.replace(
        "Charge Of This Parcel",
        "Cargo del envío"
      );
      return trip;
    },
    async handlePagoMovilAutomatizadoModal() {
      const params = {
        fullscreen: this.$vuetify.breakpoint.smAndDown,
        width: "80%",
        transition: "dialog-top-transition",
        hideCancel: true,
        persistent: true,
      };

      if (this.$vuetify.lang.current === "es") {
        const dialogInstance = await this.$dialog.showAndWait(
          AddPagoMovilAutomatizadoModal,
          params
        );
        if (dialogInstance) {
          this.getCorporatePayments();
        }
      } else {
        const dialogInstance = await this.$dialog.showAndWait(
          AddPagoMovilAutomatizadoModalEN,
          params
        );
        if (dialogInstance) {
          this.getCorporatePayments();
        }
      }
    },
    async handleZelleModal() {
      const params = {
        fullscreen: this.$vuetify.breakpoint.smAndDown,
        width: "80%",
        transition: "dialog-top-transition",
        hideCancel: true,
        persistent: true,
      };

      if (this.$vuetify.lang.current === "es") {
        const dialogInstance = await this.$dialog.showAndWait(
          AddZelleModal,
          params
        );
        if (dialogInstance) {
          this.getCorporatePayments();
        }
      } else {
        const dialogInstance = await this.$dialog.showAndWait(
          AddZelleModalEN,
          params
        );
        if (dialogInstance) {
          this.getCorporatePayments();
        }
      }
    },
    async handleManualTopUps(manualPayment) {
      const params = {
        fullscreen: this.$vuetify.breakpoint.smAndDown,
        width: "80%",
        transition: "dialog-top-transition",
        hideCancel: true,
        persistent: true,
        payload: manualPayment,
      };
      if (this.$vuetify.lang.current === "es") {
        const dialogInstance = await this.$dialog.showAndWait(
          AddManualTopUpModal,
          params
        );
        if (dialogInstance) {
          this.getPendingManualPayments();
        }
      } else {
        const dialogInstance = await this.$dialog.showAndWait(
          AddManualTopUpModal,
          params
        );
        if (dialogInstance) {
          this.getPendingManualPayments();
        }
      }
    },
    async getPendingManualPayments() {
      this.pendingValidationTable.loading = true;
      let params = {
        state_id: 0,
        page: this.pendingValidationComputedPage,
        limit: this.pendingValidationTable.limit,
      };

      try {
        const { data } = await axios.get(
          `${process.env.VUE_APP_STAGING}/v2/corporate/manual_top_ups`,
          {
            params: params,
          }
        );
        if (!!data.success) {
          if (data.data.detail.length > 0) {
            this.pendingValidationTable.items = data.data.detail;
            this.pendingValidationTable.pages = data.data.pages;
            this.pendingValidationTable.currentPage = parseFloat(
              data.data.current_page
            );
            this.pendingManualTopUpsHidden = false;
          } else {
            this.pendingManualTopUpsHidden = true;
          }
        } else {
          throw new Error("Error fetching pending manual payments");
        }
      } catch (error) {
        this.pendingManualTopUpsHidden = true;
        this.$dialog.notify.error(error.message);
      }
      this.pendingValidationTable.loading = false;
    },
    handleRefreshTables() {
      this.getPendingManualPayments();
      this.getCorporatePayments();
    },
    handleEmitManualPayment(manualPayment) {
      this.handleManualTopUps(manualPayment);
    },
  },
  created() {
    this.getPendingManualPayments();
    this.getCorporatePayments();
  },
  computed: {
    ...mapState("auth", ["wallet", "overdraft", "is_active_shipments"]),
    ...mapState("showProformModal", ["dontShowAgain"]),
    computedIconStyle() {
      if (this.wallet < 0) {
        return "color: #ef4370 !important;";
      }
      if (this.wallet === 0) {
        return "color: #000522 !important;";
      }
      if (this.wallet > 0) {
        return "color: #3BD4AE !important;";
      }
    },
    computedColor() {
      if (this.wallet < 0) {
        return "color-red-text";
      }
      if (this.wallet === 0) {
        return "color-blue-text";
      }
      if (this.wallet > 0) {
        return "color-green-text";
      }
    },
    computedButtonColor() {
      if (this.wallet < 0) {
        return "accent";
      }
      if (this.wallet === 0) {
        return "primary";
      }
      if (this.wallet > 0) {
        return "success";
      }
    },
    computedBackground() {
      if (this.wallet < 0) {
        return "salmon-background";
      }
      if (this.wallet === 0) {
        return "blue-background";
      }
      if (this.wallet > 0) {
        return "green-background";
      }
    },
    PendingManualPayments() {
      return this.table.movements;
    },
    computedPage() {
      return this.table.currentPage;
    },
    pendingValidationComputedPage() {
      return this.pendingValidationTable.currentPage;
    },
    computedMaxDate() {
      let today = new Date();
      // utc-4 ccs
      return this.$moment(today).add(-4, "hours").toISOString();
    },
    computedTablePendingManualPayments() {
      return this.pendingValidationTable.items;
    },
  },
  watch: {
    "table.limit": function (oldVal, newVal) {
      if (oldVal > newVal) {
        this.table.currentPage = 1;
      }
      this.getCorporatePayments();
    },
    "table.currentPage": function (newVal, oldVal) {
      if (!newVal) {
        this.table.currentPage = oldVal;
      }
    },
    "pendingValidationTable.limit": function (oldVal, newVal) {
      if (oldVal > newVal) {
        this.pendingValidationTable.currentPage = 1;
      }
      this.getPendingManualPayments();
    },
    "pendingValidationTable.currentPage": function (newVal, oldVal) {
      if (!newVal) {
        this.pendingValidationTable.currentPage = oldVal;
      }
    },
  },
  mounted() {
    if (this.dontShowAgain !== true) {
      this.$dialog.showAndWait(ProformModal, {
        showClose: true,
      });
    }
  },
};
</script>
<style lang="scss" scoped>
.salmon-background {
  background-color: rgba(239, 67, 112, 0.08);
}
.blue-background {
  background-color: rgb(0, 5, 34, 0.08);
}
.green-background {
  background-color: rgb(59, 212, 174, 0.08);
}
.custom-font-size {
  font-size: 48px;
}
.custom-font-size-small {
  font-size: 24px;
}
.custom-margin {
  margin-top: -10px;
}
.custom-margin-2 {
  margin-top: 75px !important;
}
a {
  text-decoration: none;
}

.listClassLight:hover {
  background-color: #fafafa;
}
.listClassDark:hover {
  background-color: #3b355a;
}
.arrow-down {
  transform: rotate(0deg);
  transition: transform 0.1s linear;
}

.arrow-down.open {
  transform: rotate(180deg);
  transition: transform 0.1s linear;
}
</style>
